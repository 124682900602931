<template>
  <div class="service">
    <div class="Section0" style="layout-grid:15.6000pt;">
      <p class=MsoNormal align=center style="text-align:center;"><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:22.0000pt;mso-font-kerning:1.0000pt;">Terms&nbsp;of&nbsp;Service</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:22.0000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:22.0000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">In&nbsp;order&nbsp;to&nbsp;protect&nbsp;your&nbsp;rights,&nbsp;please&nbsp;read&nbsp;all&nbsp;the&nbsp;contents&nbsp;of&nbsp;the&nbsp;Terms&nbsp;of&nbsp;Service.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">1.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Terms&nbsp;of&nbsp;Acknowledge&nbsp;and&nbsp;Acceptance</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
					<o:p>&nbsp;</o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">When&nbsp;you&nbsp;register&nbsp;as&nbsp;a&nbsp;user,&nbsp;you&nbsp;may&nbsp;use&nbsp;all&nbsp;games&nbsp;and&nbsp;services&nbsp;offered&nbsp;by&nbsp;Evergreen.&nbsp;We&nbsp;may&nbsp;determine&nbsp;that&nbsp;you&nbsp;have&nbsp;read,&nbsp;understood&nbsp;and&nbsp;agreed&nbsp;on&nbsp;the&nbsp;terms.&nbsp;When&nbsp;using&nbsp;the&nbsp;services,&nbsp;users&nbsp;are&nbsp;considered&nbsp;to&nbsp;have&nbsp;agreed&nbsp;to&nbsp;abide&nbsp;by&nbsp;the&nbsp;Terms&nbsp;of&nbsp;Service,&nbsp;to&nbsp;have&nbsp;agreed&nbsp;to&nbsp;accept&nbsp;the&nbsp;User&nbsp;Principles&nbsp;(including&nbsp;the&nbsp;game&nbsp;rules,&nbsp;service&nbsp;center,&nbsp;notices&nbsp;and&nbsp;other&nbsp;personal&nbsp;service&nbsp;specifications)&nbsp;of&nbsp;Evergreen&nbsp;(referred&nbsp;to&nbsp;as&nbsp;&#8220;we&#8221;&nbsp;in&nbsp;some&nbsp;cases)&nbsp;and&nbsp;to&nbsp;comply&nbsp;with&nbsp;relevant&nbsp;legal&nbsp;requirements.&nbsp;If&nbsp;you&nbsp;are&nbsp;under&nbsp;20&nbsp;years&nbsp;old,&nbsp;in&nbsp;addition&nbsp;to&nbsp;meeting&nbsp;the&nbsp;above&nbsp;requirements,&nbsp;your&nbsp;parents&nbsp;(or&nbsp;legal&nbsp;representative&nbsp;or&nbsp;guardian)&nbsp;shall&nbsp;read,&nbsp;understand&nbsp;and&nbsp;agree&nbsp;on&nbsp;the&nbsp;TOS
				<font face="宋体">（</font>
				<font face="Times New Roman">Terms&nbsp;of&nbsp;Service</font>
				<font face="宋体">）&nbsp;</font>
				<font face="Times New Roman">
					so&nbsp;that&nbsp;you&nbsp;can&nbsp;continue&nbsp;to&nbsp;enjoy&nbsp;the&nbsp;Service.</font>
			</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">2.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Update&nbsp;and&nbsp;Modification&nbsp;of&nbsp;the&nbsp;Terms&nbsp;of&nbsp;Service</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
					<o:p>&nbsp;</o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">Evergreen&nbsp;reserves&nbsp;the&nbsp;right&nbsp;to&nbsp;add&nbsp;and&nbsp;modify&nbsp;the&nbsp;terms&nbsp;of&nbsp;membership&nbsp;and&nbsp;any&nbsp;terms.&nbsp;In&nbsp;case&nbsp;of&nbsp;any&nbsp;changes&nbsp;to&nbsp;the&nbsp;Terms&nbsp;of&nbsp;Service,&nbsp;Evergreen&nbsp;will&nbsp;post&nbsp;them&nbsp;on&nbsp;the&nbsp;home&nbsp;page&nbsp;of&nbsp;the&nbsp;site.&nbsp;No&nbsp;personal&nbsp;notice&nbsp;will&nbsp;be&nbsp;given.&nbsp;If&nbsp;you&nbsp;do&nbsp;not&nbsp;agree&nbsp;on&nbsp;the&nbsp;above&nbsp;contents,&nbsp;please&nbsp;do&nbsp;not&nbsp;continue&nbsp;to&nbsp;use&nbsp;the&nbsp;Service.&nbsp;If&nbsp;you&nbsp;do&nbsp;use&nbsp;the&nbsp;Service,&nbsp;you&nbsp;will&nbsp;be&nbsp;deemed&nbsp;to&nbsp;have&nbsp;accepted&nbsp;and&nbsp;agreed&nbsp;to&nbsp;the&nbsp;revised&nbsp;terms.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">3.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Responsibilities&nbsp;and&nbsp;Obligations&nbsp;of&nbsp;Users</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
					<o:p>&nbsp;</o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.1</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">You&nbsp;agree&nbsp;and&nbsp;confirm&nbsp;that&nbsp;the&nbsp;personal&nbsp;information&nbsp;used&nbsp;for&nbsp;registration&nbsp;and&nbsp;login&nbsp;is&nbsp;correct,&nbsp;complete&nbsp;and&nbsp;true.&nbsp;If&nbsp;the&nbsp;data&nbsp;has&nbsp;been&nbsp;changed,&nbsp;the&nbsp;online&nbsp;update&nbsp;shall&nbsp;be&nbsp;kept.&nbsp;Evergreen&nbsp;will&nbsp;reserve&nbsp;the&nbsp;right&nbsp;to&nbsp;terminate&nbsp;your&nbsp;user&nbsp;qualification&nbsp;and&nbsp;use&nbsp;of&nbsp;the&nbsp;services&nbsp;at&nbsp;any&nbsp;time&nbsp;when&nbsp;your&nbsp;personal&nbsp;information&nbsp;or&nbsp;original&nbsp;login&nbsp;information&nbsp;no&nbsp;longer&nbsp;satisfies&nbsp;the&nbsp;condition&nbsp;of&nbsp;being&nbsp;true&nbsp;but&nbsp;not&nbsp;being&nbsp;updated&nbsp;or&nbsp;when&nbsp;there&nbsp;is&nbsp;any&nbsp;misleading&nbsp;information.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.2</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">You&nbsp;agree&nbsp;and&nbsp;acknowledge&nbsp;that&nbsp;you&nbsp;will&nbsp;not&nbsp;do&nbsp;any&nbsp;of&nbsp;the&nbsp;following&nbsp;actions,&nbsp;otherwise&nbsp;Evergreen&nbsp;reserves&nbsp;the&nbsp;right&nbsp;to&nbsp;block&nbsp;your&nbsp;account&nbsp;or&nbsp;take&nbsp;measures&nbsp;including&nbsp;but&nbsp;not&nbsp;limited&nbsp;to&nbsp;disabling&nbsp;you&nbsp;to&nbsp;send&nbsp;messages,&nbsp;blocking&nbsp;you&nbsp;from&nbsp;speaking&nbsp;or&nbsp;not&nbsp;cooperating&nbsp;with&nbsp;your&nbsp;requests:</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.2.1</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">Users&nbsp;shall&nbsp;not&nbsp;use&nbsp;the&nbsp;Service&nbsp;for&nbsp;any&nbsp;commercial&nbsp;activity.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.2.2</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">In&nbsp;addition&nbsp;to&nbsp;complying&nbsp;with&nbsp;the&nbsp;Terms&nbsp;of&nbsp;Service,&nbsp;you&nbsp;shall&nbsp;agree&nbsp;to&nbsp;abide&nbsp;by&nbsp;the&nbsp;conventions&nbsp;set&nbsp;forth&nbsp;in&nbsp;the&nbsp;relevant&nbsp;regulations&nbsp;and&nbsp;the&nbsp;international&nbsp;use&nbsp;of&nbsp;the&nbsp;Internet.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.2.3</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">Users&nbsp;shall&nbsp;not&nbsp;use&nbsp;the&nbsp;Service&nbsp;to&nbsp;send&nbsp;or&nbsp;publish&nbsp;abusive,&nbsp;defamatory,&nbsp;obscene,&nbsp;lewd,&nbsp;offensive&nbsp;or&nbsp;other&nbsp;information&nbsp;involving&nbsp;articles&nbsp;or&nbsp;pictures.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.2.4</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">Users&nbsp;shall&nbsp;agree&nbsp;to&nbsp;fully&nbsp;respect&nbsp;intellectual&nbsp;property&nbsp;rights&nbsp;and&nbsp;be&nbsp;prohibited&nbsp;from&nbsp;posting&nbsp;texts,&nbsp;images&nbsp;or&nbsp;any&nbsp;other&nbsp;documents&nbsp;that&nbsp;infringe&nbsp;on&nbsp;intellectual&nbsp;property&nbsp;rights.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.3</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">You&nbsp;know&nbsp;and&nbsp;agree&nbsp;that&nbsp;all&nbsp;contents&nbsp;publicly&nbsp;posted&nbsp;on&nbsp;Evergreen&#8217;s&nbsp;web&nbsp;platform&nbsp;shall&nbsp;be&nbsp;reviewed&nbsp;by&nbsp;Evergreen&nbsp;before&nbsp;release.&nbsp;In&nbsp;the&nbsp;case&nbsp;that&nbsp;Evergreen&nbsp;believes&nbsp;that&nbsp;your&nbsp;comments&nbsp;or&nbsp;pictures&nbsp;or&nbsp;videos&nbsp;are&nbsp;abusive,&nbsp;defamatory,&nbsp;obscene,&nbsp;lewd&nbsp;or&nbsp;offensive&nbsp;or&nbsp;otherwise&nbsp;in&nbsp;violation&nbsp;of&nbsp;the&nbsp;Service&nbsp;Agreement,&nbsp;Evergreen&nbsp;reserves&nbsp;the&nbsp;right&nbsp;to&nbsp;take&nbsp;measures&nbsp;such&nbsp;as&nbsp;not&nbsp;posting,&nbsp;blocking&nbsp;or&nbsp;banning&nbsp;accounts&nbsp;on&nbsp;the&nbsp;web&nbsp;platform,&nbsp;etc.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.3.1</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">You&nbsp;understand&nbsp;that&nbsp;the&nbsp;ownership&nbsp;of&nbsp;the&nbsp;Service&nbsp;will&nbsp;be&nbsp;remained&nbsp;by&nbsp;Evergreen&nbsp;when&nbsp;you&nbsp;become&nbsp;a&nbsp;user&nbsp;after&nbsp;you&nbsp;begin&nbsp;using&nbsp;the&nbsp;service.&nbsp;Users&nbsp;may&nbsp;only&nbsp;use&nbsp;the&nbsp;services&nbsp;in&nbsp;accordance&nbsp;with&nbsp;these&nbsp;terms.&nbsp;Users&nbsp;shall&nbsp;not&nbsp;rent,&nbsp;lend,&nbsp;transfer&nbsp;or&nbsp;assign&nbsp;their&nbsp;ID&nbsp;cards&nbsp;to&nbsp;any&nbsp;other&nbsp;person.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.3.2</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">Users&nbsp;must&nbsp;retain&nbsp;their&nbsp;accounts,&nbsp;passwords&nbsp;and&nbsp;other&nbsp;user&nbsp;information,&nbsp;and&nbsp;must&nbsp;be&nbsp;responsible&nbsp;for&nbsp;all&nbsp;account&nbsp;activities.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.3.3</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">If&nbsp;you&nbsp;become&nbsp;aware&nbsp;that&nbsp;your&nbsp;account&nbsp;or&nbsp;password&nbsp;is&nbsp;being&nbsp;used&nbsp;illegally,&nbsp;you&nbsp;shall&nbsp;report&nbsp;it&nbsp;to&nbsp;Evergreen&nbsp;immediately.&nbsp;However,&nbsp;Evergreen&nbsp;will&nbsp;not&nbsp;be&nbsp;responsible&nbsp;for&nbsp;handling&nbsp;any&nbsp;illegal&nbsp;use&nbsp;of&nbsp;your&nbsp;account&nbsp;or&nbsp;password&nbsp;that&nbsp;results&nbsp;from&nbsp;your&nbsp;negligence.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">4.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Termination&nbsp;and&nbsp;Change&nbsp;of&nbsp;Service</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
					<o:p>&nbsp;</o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">If&nbsp;any&nbsp;user&nbsp;acts&nbsp;as&nbsp;any&nbsp;of&nbsp;the&nbsp;following&nbsp;cases,&nbsp;Evergreen&nbsp;reserves&nbsp;the&nbsp;right&nbsp;to&nbsp;terminate&nbsp;his&nbsp;or&nbsp;her&nbsp;account&nbsp;without&nbsp;giving&nbsp;a&nbsp;notice&nbsp;or&nbsp;perform&nbsp;the&nbsp;following&nbsp;actions&nbsp;on&nbsp;his&nbsp;or&nbsp;her&nbsp;account:&nbsp;ban&nbsp;to&nbsp;post,&nbsp;force&nbsp;to&nbsp;be&nbsp;offline;&nbsp;temporarily&nbsp;freeze&nbsp;play;&nbsp;permanently&nbsp;freeze&nbsp;play;&nbsp;temporarily&nbsp;ban&nbsp;login;&nbsp;permanently&nbsp;ban&nbsp;login;&nbsp;delete&nbsp;save&nbsp;data&nbsp;,&nbsp;etc.&nbsp;If&nbsp;any&nbsp;user&nbsp;causes&nbsp;damage&nbsp;to&nbsp;us&nbsp;or&nbsp;any&nbsp;third&nbsp;party,&nbsp;we&nbsp;reserve&nbsp;the&nbsp;right&nbsp;to&nbsp;pursue&nbsp;his&nbsp;or&nbsp;her&nbsp;legal&nbsp;responsibilities.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.1&nbsp;Register&nbsp;by&nbsp;false&nbsp;personal&nbsp;information.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.2&nbsp;Register&nbsp;by&nbsp;using&nbsp;&#8220;Evergreen&#8221;&nbsp;or&nbsp;similar&nbsp;names.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.3&nbsp;Use&nbsp;characters&nbsp;other&nbsp;than&nbsp;English&nbsp;letters&nbsp;and&nbsp;numbers&nbsp;or&nbsp;use&nbsp;profane&nbsp;names.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.4&nbsp;Apply&nbsp;for&nbsp;an&nbsp;ID&nbsp;card&nbsp;by&nbsp;fraudulently&nbsp;using&nbsp;others&#8217;&nbsp;name.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.5&nbsp;Store&nbsp;value&nbsp;through&nbsp;false&nbsp;or&nbsp;unfair&nbsp;means,&nbsp;or&nbsp;topping&nbsp;up&nbsp;through&nbsp;unofficially&nbsp;approved&nbsp;top-up&nbsp;channels.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.6&nbsp;Violate&nbsp;the&nbsp;Terms&nbsp;of&nbsp;Service.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.7&nbsp;Register&nbsp;multiple&nbsp;accounts/game&nbsp;characters&nbsp;(each&nbsp;real&nbsp;player&nbsp;identity&nbsp;is&nbsp;limited&nbsp;to&nbsp;only&nbsp;one&nbsp;account)&nbsp;for&nbsp;the&nbsp;purpose&nbsp;of&nbsp;profit&nbsp;or&nbsp;other&nbsp;gaming&nbsp;behaviors&nbsp;that&nbsp;damages&nbsp;the&nbsp;game&nbsp;environment.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.8&nbsp;Engage&nbsp;in&nbsp;in-game&nbsp;single&nbsp;or&nbsp;series&nbsp;output&nbsp;play&nbsp;and&nbsp;sell&nbsp;the&nbsp;obtained&nbsp;virtual&nbsp;items&nbsp;for&nbsp;profit.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.9&nbsp;Buy&nbsp;and&nbsp;sell&nbsp;virtual&nbsp;goods&nbsp;on&nbsp;different&nbsp;servers&nbsp;for&nbsp;profit&nbsp;by&nbsp;taking&nbsp;advantage&nbsp;of&nbsp;the&nbsp;difference&nbsp;in&nbsp;the&nbsp;value&nbsp;of&nbsp;virtual&nbsp;goods&nbsp;on&nbsp;different&nbsp;servers.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.10&nbsp;Post&nbsp;abusive,&nbsp;defamatory,&nbsp;obscene,&nbsp;lewd&nbsp;or&nbsp;offensive&nbsp;words&nbsp;or&nbsp;other&nbsp;words&nbsp;that&nbsp;affect&nbsp;the&nbsp;normal&nbsp;gaming&nbsp;of&nbsp;others.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.11&nbsp;Interfere&nbsp;with,&nbsp;create,&nbsp;publish,&nbsp;transmit,&nbsp;or&nbsp;use&nbsp;any&nbsp;form&nbsp;of&nbsp;auxiliary&nbsp;tools&nbsp;or&nbsp;programs&nbsp;that&nbsp;interfere&nbsp;with&nbsp;the&nbsp;fairness&nbsp;of&nbsp;the&nbsp;game&nbsp;(including&nbsp;but&nbsp;not&nbsp;limited&nbsp;to&nbsp;&#8220;plug-ins&#8221;,&nbsp;wherein&nbsp;&#8220;plug-ins&#8221;&nbsp;refer&nbsp;to&nbsp;all&nbsp;programs&nbsp;that&nbsp;are&nbsp;independent&nbsp;of&nbsp;the&nbsp;game&nbsp;software&nbsp;and&nbsp;can&nbsp;affect&nbsp;the&nbsp;operation&nbsp;of&nbsp;the&nbsp;game&nbsp;while&nbsp;the&nbsp;game&nbsp;is&nbsp;running,&nbsp;including&nbsp;but&nbsp;not&nbsp;limited&nbsp;to&nbsp;emulation&nbsp;of&nbsp;users&#8217;&nbsp;operation,&nbsp;changing&nbsp;the&nbsp;operating&nbsp;environment,&nbsp;modifying&nbsp;data,&nbsp;etc.).</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.12&nbsp;Promote&nbsp;or&nbsp;abet&nbsp;other&nbsp;users&nbsp;to&nbsp;use&nbsp;plug-ins,&nbsp;private&nbsp;servicer&nbsp;and&nbsp;Trojan-horse-related&nbsp;contents.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.13&nbsp;Publish&nbsp;any&nbsp;homepage&nbsp;address&nbsp;or&nbsp;link&nbsp;that&nbsp;we&nbsp;reasonably&nbsp;judge&nbsp;to&nbsp;be&nbsp;inappropriate&nbsp;or&nbsp;that&nbsp;we&nbsp;do&nbsp;not&nbsp;approve,&nbsp;including&nbsp;software,&nbsp;files,&nbsp;etc.,&nbsp;or&nbsp;disseminate&nbsp;or&nbsp;spread&nbsp;false&nbsp;information&nbsp;to&nbsp;other&nbsp;players&nbsp;through&nbsp;various&nbsp;means&nbsp;or&nbsp;acts&nbsp;by&nbsp;impersonating&nbsp;the&nbsp;system.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.14&nbsp;Illegally&nbsp;trade&nbsp;virtual&nbsp;game&nbsp;items&nbsp;and&nbsp;accounts.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.15&nbsp;In&nbsp;the&nbsp;game,&nbsp;disrupt&nbsp;the&nbsp;normal&nbsp;operation&nbsp;of&nbsp;the&nbsp;game&nbsp;by&nbsp;using&nbsp;the&nbsp;bugs&nbsp;and&nbsp;errors&nbsp;of&nbsp;the&nbsp;programs&nbsp;or&nbsp;spread&nbsp;the&nbsp;bugs&nbsp;or&nbsp;errors,&nbsp;make&nbsp;profits&nbsp;for&nbsp;himself&nbsp;or&nbsp;herself&nbsp;and&nbsp;others&nbsp;by&nbsp;using&nbsp;possible&nbsp;technical&nbsp;defects&nbsp;or&nbsp;bugs&nbsp;of&nbsp;the&nbsp;software&nbsp;or&nbsp;online&nbsp;game&nbsp;system&nbsp;in&nbsp;various&nbsp;forms&nbsp;(including&nbsp;but&nbsp;not&nbsp;limited&nbsp;to&nbsp;copying&nbsp;virtual&nbsp;game&nbsp;items,&nbsp;stealing&nbsp;virtual&nbsp;currency&nbsp;and&nbsp;items,&nbsp;etc.)&nbsp;or&nbsp;engage&nbsp;in&nbsp;other&nbsp;improper&nbsp;acts,&nbsp;etc.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">5.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Suspension&nbsp;or&nbsp;Interruption&nbsp;of&nbsp;Service</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
					<o:p>&nbsp;</o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">Evergreen&nbsp;reserves&nbsp;the&nbsp;right&nbsp;to&nbsp;suspend&nbsp;or&nbsp;terminate&nbsp;all&nbsp;or&nbsp;part&nbsp;of&nbsp;the&nbsp;Services&nbsp;under&nbsp;the&nbsp;following&nbsp;cases:</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">5.1&nbsp;Hardware&nbsp;and&nbsp;software&nbsp;for&nbsp;the&nbsp;maintenance&nbsp;and&nbsp;construction&nbsp;of&nbsp;services&nbsp;and&nbsp;electronic&nbsp;communication&nbsp;equipment.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">5.2&nbsp;Sudden&nbsp;failure&nbsp;of&nbsp;hardware&nbsp;and&nbsp;software&nbsp;equipment&nbsp;and&nbsp;electronic&nbsp;communication&nbsp;equipment.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">5.3&nbsp;Unable&nbsp;to&nbsp;provide&nbsp;service&nbsp;due&nbsp;to&nbsp;force&nbsp;majeure&nbsp;such&nbsp;as&nbsp;natural&nbsp;disasters&nbsp;and&nbsp;so&nbsp;on.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">5.4&nbsp;To&nbsp;protect&nbsp;other&nbsp;members&nbsp;or&nbsp;any&nbsp;third&nbsp;party&nbsp;and&nbsp;the&nbsp;integrity&nbsp;of&nbsp;data&nbsp;security&nbsp;in&nbsp;emergencies.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">6.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Change&nbsp;of&nbsp;Service&nbsp;Rights&nbsp;</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
					<o:p>&nbsp;</o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">Evergreen&nbsp;reserves&nbsp;the&nbsp;right&nbsp;to&nbsp;add,&nbsp;modify&nbsp;or&nbsp;delete&nbsp;all&nbsp;or&nbsp;part&nbsp;of&nbsp;the&nbsp;services&nbsp;without&nbsp;prior&nbsp;notice.&nbsp;Accordingly,&nbsp;users&nbsp;will&nbsp;not&nbsp;be&nbsp;required&nbsp;to&nbsp;pay&nbsp;any&nbsp;compensation&nbsp;or&nbsp;damages.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">7.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Responsibilities</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
					<o:p>&nbsp;</o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">Subject&nbsp;to&nbsp;Evergreen&#8217;s&nbsp;current&nbsp;plans,&nbsp;the&nbsp;Service&nbsp;is&nbsp;provided&nbsp;to&nbsp;certain&nbsp;users&nbsp;upon&nbsp;specific&nbsp;request;&nbsp;and&nbsp;Evergreen&nbsp;does&nbsp;not&nbsp;guarantee&nbsp;that&nbsp;your&nbsp;request&nbsp;for&nbsp;the&nbsp;Service&nbsp;will&nbsp;be&nbsp;met.&nbsp;The&nbsp;Service&nbsp;may&nbsp;result&nbsp;in&nbsp;software&nbsp;or&nbsp;hardware&nbsp;failure&nbsp;for&nbsp;its&nbsp;own&nbsp;or&nbsp;related&nbsp;reasons&nbsp;or&nbsp;due&nbsp;to&nbsp;other&nbsp;unavailable&nbsp;interruptions,&nbsp;delays,&nbsp;errors&nbsp;or&nbsp;any&nbsp;third&nbsp;party&#8217;s&nbsp;attacks.&nbsp;Evergreen&nbsp;will&nbsp;not&nbsp;claim&nbsp;that&nbsp;the&nbsp;Service&nbsp;is&nbsp;free&nbsp;of&nbsp;any&nbsp;interruption&nbsp;or&nbsp;delay&nbsp;with&nbsp;respect&nbsp;to&nbsp;such&nbsp;problems.&nbsp;Users&nbsp;shall&nbsp;be&nbsp;aware&nbsp;that&nbsp;there&nbsp;may&nbsp;be&nbsp;some&nbsp;uncertainty&nbsp;and&nbsp;damage&nbsp;to&nbsp;the&nbsp;Service&nbsp;and&nbsp;that&nbsp;it&nbsp;is&nbsp;not&nbsp;a&nbsp;reason&nbsp;for&nbsp;requesting&nbsp;a&nbsp;return.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">8.Copyright</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">Evergreen&nbsp;reserves&nbsp;copyrights,&nbsp;patents,&nbsp;trademarks,&nbsp;trade&nbsp;secrets,&nbsp;other&nbsp;intellectual&nbsp;property&nbsp;rights,&nbsp;proprietary&nbsp;or&nbsp;other&nbsp;rights&nbsp;of&nbsp;all&nbsp;works&nbsp;and&nbsp;information&nbsp;on&nbsp;this&nbsp;site.&nbsp;Users&nbsp;shall&nbsp;not&nbsp;use,&nbsp;transfer,&nbsp;edit&nbsp;or&nbsp;modify&nbsp;the&nbsp;works&nbsp;or&nbsp;articles&nbsp;in&nbsp;any&nbsp;way&nbsp;without&nbsp;prior&nbsp;authorization&nbsp;from&nbsp;Evergreen.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">9.Disclaimer</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
					<o:p>&nbsp;</o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">9.1&nbsp;We&nbsp;neither&nbsp;guarantee&nbsp;that&nbsp;the&nbsp;network&nbsp;services&nbsp;will&nbsp;meet&nbsp;users&#8217;&nbsp;requirements&nbsp;and&nbsp;that&nbsp;the&nbsp;network&nbsp;services&nbsp;will&nbsp;not&nbsp;be&nbsp;uninterrupted&nbsp;nor&nbsp;guarantee&nbsp;the&nbsp;timeliness,&nbsp;security&nbsp;or&nbsp;accuracy&nbsp;of&nbsp;the&nbsp;network&nbsp;services.&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">9.2&nbsp;We&nbsp;do&nbsp;not&nbsp;guarantee&nbsp;the&nbsp;accuracy&nbsp;and&nbsp;completeness&nbsp;of&nbsp;external&nbsp;links&nbsp;set&nbsp;up&nbsp;for&nbsp;the&nbsp;convenience&nbsp;of&nbsp;users.&nbsp;Meanwhile,&nbsp;we&nbsp;do&nbsp;not&nbsp;assume&nbsp;any&nbsp;responsibility&nbsp;for&nbsp;the&nbsp;contents&nbsp;of&nbsp;any&nbsp;web&nbsp;pages&nbsp;to&nbsp;which&nbsp;such&nbsp;external&nbsp;links&nbsp;lead&nbsp;and&nbsp;that&nbsp;are&nbsp;not&nbsp;under&nbsp;our&nbsp;actual&nbsp;control.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">9.3&nbsp;We&nbsp;shall&nbsp;not&nbsp;be&nbsp;liable&nbsp;for&nbsp;losses&nbsp;arising&nbsp;from&nbsp;telecommunications&nbsp;system&nbsp;or&nbsp;Internet&nbsp;failures,&nbsp;computer&nbsp;failures&nbsp;or&nbsp;viruses,&nbsp;damage&nbsp;to&nbsp;or&nbsp;loss&nbsp;of&nbsp;information,&nbsp;computer&nbsp;system&nbsp;problems&nbsp;or&nbsp;any&nbsp;other&nbsp;force&nbsp;majeure,&nbsp;provided&nbsp;that&nbsp;we&nbsp;will&nbsp;try&nbsp;our&nbsp;best&nbsp;to&nbsp;minimize&nbsp;the&nbsp;losses&nbsp;and&nbsp;impact&nbsp;on&nbsp;users&nbsp;herefrom.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">9.4&nbsp;Users&nbsp;may&nbsp;link&nbsp;to&nbsp;any&nbsp;third-party&nbsp;sites&nbsp;when&nbsp;using&nbsp;our&nbsp;products&nbsp;and&nbsp;services.&nbsp;Third-party&nbsp;sites&nbsp;are&nbsp;not&nbsp;under&nbsp;our&nbsp;control&nbsp;and&nbsp;we&nbsp;are&nbsp;not&nbsp;responsible&nbsp;for&nbsp;the&nbsp;contents&nbsp;of&nbsp;any&nbsp;third-party&nbsp;sites,&nbsp;any&nbsp;links&nbsp;contained&nbsp;in&nbsp;such&nbsp;third-party&nbsp;sites,&nbsp;or&nbsp;any&nbsp;changes&nbsp;or&nbsp;updates&nbsp;to&nbsp;such&nbsp;third-party&nbsp;sites.&nbsp;We&nbsp;provide&nbsp;these&nbsp;links&nbsp;to&nbsp;third-party&nbsp;sites&nbsp;to&nbsp;users&nbsp;only&nbsp;for&nbsp;convenience,&nbsp;and&nbsp;our&nbsp;provision&nbsp;of&nbsp;such&nbsp;links&nbsp;does&nbsp;not&nbsp;mean&nbsp;that&nbsp;we&nbsp;endorse&nbsp;such&nbsp;third-party&nbsp;sites.&nbsp;Users&nbsp;shall&nbsp;check&nbsp;and&nbsp;comply&nbsp;with&nbsp;the&nbsp;rules&nbsp;of&nbsp;such&nbsp;third-party&nbsp;sites.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">9.5&nbsp;In&nbsp;case&nbsp;of&nbsp;users&#8217;&nbsp;violation&nbsp;of&nbsp;the&nbsp;relevant&nbsp;rules&nbsp;as&nbsp;agreed&nbsp;in&nbsp;the&nbsp;Agreement,&nbsp;if&nbsp;we&nbsp;have&nbsp;taken&nbsp;relevant&nbsp;measures&nbsp;in&nbsp;accordance&nbsp;with&nbsp;the&nbsp;Agreement,&nbsp;we&nbsp;shall&nbsp;not&nbsp;compensate&nbsp;for&nbsp;any&nbsp;damages.&nbsp;Besides,&nbsp;in&nbsp;case&nbsp;of&nbsp;any&nbsp;damages&nbsp;to&nbsp;any&nbsp;third&nbsp;party&nbsp;caused&nbsp;by&nbsp;users&nbsp;herefrom,&nbsp;we&nbsp;shall&nbsp;not&nbsp;be&nbsp;liable&nbsp;for&nbsp;any&nbsp;compensation.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">10.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Other&nbsp;Terms&nbsp;and&nbsp;Conditions</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
					<o:p>&nbsp;</o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">10.1</span><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">If&nbsp;any&nbsp;provision&nbsp;of&nbsp;the&nbsp;Agreement&nbsp;is,&nbsp;for&nbsp;whatever&nbsp;reason,&nbsp;wholly&nbsp;or&nbsp;partially&nbsp;invalid&nbsp;or&nbsp;unenforceable,&nbsp;or&nbsp;violates&nbsp;any&nbsp;applicable&nbsp;law,&nbsp;such&nbsp;provision&nbsp;shall&nbsp;be&nbsp;deemed&nbsp;to&nbsp;have&nbsp;been&nbsp;deleted,&nbsp;provided&nbsp;that&nbsp;the&nbsp;remaining&nbsp;provisions&nbsp;of&nbsp;the&nbsp;Agreement&nbsp;shall&nbsp;remain&nbsp;valid&nbsp;and&nbsp;binding.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">10.2</span><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;reserve&nbsp;the&nbsp;right&nbsp;to&nbsp;modify&nbsp;the&nbsp;Agreement&nbsp;at&nbsp;any&nbsp;time&nbsp;in&nbsp;accordance&nbsp;with&nbsp;changes&nbsp;in&nbsp;relevant&nbsp;laws&nbsp;and&nbsp;regulations&nbsp;as&nbsp;well&nbsp;as&nbsp;adjustments&nbsp;to&nbsp;the&nbsp;company&#8217;s&nbsp;business&nbsp;conditions&nbsp;and&nbsp;strategies&nbsp;without&nbsp;giving&nbsp;any&nbsp;separate&nbsp;notice&nbsp;to&nbsp;users.&nbsp;The&nbsp;amended&nbsp;agreement&nbsp;will&nbsp;be&nbsp;announced&nbsp;on&nbsp;the&nbsp;game&nbsp;announcement.&nbsp;In&nbsp;the&nbsp;event&nbsp;of&nbsp;any&nbsp;dispute,&nbsp;the&nbsp;latest&nbsp;text&nbsp;of&nbsp;the&nbsp;agreement&nbsp;shall&nbsp;prevail.&nbsp;If&nbsp;you&nbsp;do&nbsp;not&nbsp;agree&nbsp;with&nbsp;the&nbsp;modifications&nbsp;we&nbsp;have&nbsp;made&nbsp;to&nbsp;the&nbsp;terms&nbsp;of&nbsp;the&nbsp;Agreement,&nbsp;you&nbsp;have&nbsp;the&nbsp;right&nbsp;to&nbsp;stop&nbsp;using&nbsp;the&nbsp;network&nbsp;services.&nbsp;If&nbsp;continue&nbsp;to&nbsp;use&nbsp;the&nbsp;network&nbsp;services,&nbsp;you&nbsp;will&nbsp;be&nbsp;deemed&nbsp;to&nbsp;have&nbsp;accepted&nbsp;the&nbsp;modifications&nbsp;we&nbsp;have&nbsp;made&nbsp;to&nbsp;the&nbsp;terms&nbsp;of&nbsp;the&nbsp;Agreement.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">10.3&nbsp;We&nbsp;reserve&nbsp;the&nbsp;right&nbsp;to&nbsp;interpret&nbsp;and&nbsp;amend&nbsp;the&nbsp;Agreement&nbsp;to&nbsp;the&nbsp;fullest&nbsp;extent&nbsp;permitted&nbsp;by&nbsp;law.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;color:rgb(68,68,68);font-size:12.0000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.service {
  height: calc(100vh - 55px);
  overflow: hidden;
  overflow-y: auto;
  padding:0 1rem;
  word-break: break-word;
  &::-webkit-scrollbar {
    display: none;
  }
}
@font-face {
  font-family: "Times New Roman";
}

@font-face {
  font-family: "宋体";
}

@font-face {
  font-family: "Calibri";
}

@font-face {
  font-family: "DejaVu Sans";
}

p.MsoNormal {
  mso-style-name: 正文;
  mso-style-parent: "";
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

h1 {
  mso-style-name: "标题 1";
  mso-style-next: 正文;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 24.0000pt;
  mso-font-kerning: 22.0000pt;
}

h2 {
  mso-style-name: "标题 2";
  mso-style-next: 正文;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 18.0000pt;
}

h3 {
  mso-style-name: "标题 3";
  mso-style-next: 正文;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 13.5000pt;
}

span.10 {
  font-family: 'Times New Roman';
}

span.15 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.16 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.17 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.18 {
  font-family: 'Times New Roman';
  font-size: 10.5000pt;
}

p.p {
  mso-style-name: "普通\(网站\)";
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
}

p.MsoAcetate {
  mso-style-name: 批注框文本;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoCommentText {
  mso-style-name: 批注文字;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoCommentSubject {
  mso-style-name: 批注主题;
  mso-style-parent: 批注文字;
  mso-style-next: 批注文字;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.msoIns {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: underline;
  text-underline: single;
  color: blue;
}

span.msoDel {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: line-through;
  color: red;
}

table.MsoNormalTable {
  mso-style-name: 普通表格;
  mso-style-parent: "";
  mso-style-noshow: yes;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin: 0pt;
  mso-para-margin-bottom: .0001pt;
  mso-pagination: widow-orphan;
  font-family: 'Times New Roman';
  font-size: 10.0000pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}

table.MsoTableGrid {
  mso-style-name: 网格型;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-border-top-alt: 0.7500pt solid rgb(0, 0, 0);
  mso-border-left-alt: 0.7500pt solid rgb(0, 0, 0);
  mso-border-bottom-alt: 0.7500pt solid rgb(0, 0, 0);
  mso-border-right-alt: 0.7500pt solid rgb(0, 0, 0);
  mso-border-insideh: 0.7500pt solid rgb(0, 0, 0);
  mso-border-insidev: 0.7500pt solid rgb(0, 0, 0);
  mso-para-margin: 0pt;
  mso-para-margin-bottom: .0001pt;
  mso-pagination: widow-orphan;
  font-family: 'Times New Roman';
  font-size: 10.0000pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}

@page {
  mso-page-border-surround-header: no;
  mso-page-border-surround-footer: no;
}

@page Section0 {
  margin-top: 72.0000pt;
  margin-bottom: 72.0000pt;
  margin-left: 90.0000pt;
  margin-right: 90.0000pt;
  size: 595.2500pt 841.8500pt;
  layout-grid: 15.6000pt;
}

div.Section0 {
  page: Section0;
}
</style>
