<template>
  <div class="contact">
    <div>
      <p>If you want to get more information or have any questions, please contact the following e-mail address.</p>
      <a href="mailto:business@evergreenrealm.com"> business@evergreenrealm.com </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 55px);
  overflow: hidden;
  overflow-y: auto;
  padding: 0 25px;
  &::-webkit-scrollbar {
    display: none;
  }
  p {
    font-size: 24px;
  }
}
</style>
