<template>
  <div class="privacy">
    <div class="Section0" style="layout-grid:15.6000pt;">
      <p class=MsoNormal align=center style="text-align:center;"><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:22.0000pt;mso-font-kerning:1.0000pt;">Privacy&nbsp;Policy</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:22.0000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Overview</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">Welcome&nbsp;to&nbsp;choose&nbsp;games&nbsp;provided&nbsp;by&nbsp;Evergreen&nbsp;(hereinafter&nbsp;referred&nbsp;to&nbsp;as&nbsp;&#8220;we&#8221;)!&nbsp;We&nbsp;regard&nbsp;the&nbsp;security&nbsp;and&nbsp;privacy&nbsp;of&nbsp;our&nbsp;users&nbsp;as&nbsp;our&nbsp;&#8220;essential&#8221;.&nbsp;Adhering&nbsp;to&nbsp;the&nbsp;concept&nbsp;that&nbsp;&#8220;everything&nbsp;is&nbsp;based&nbsp;on&nbsp;user&nbsp;value&#8221;,&nbsp;we&nbsp;are&nbsp;committed&nbsp;to&nbsp;improving&nbsp;the&nbsp;information&nbsp;processing&nbsp;transparency,&nbsp;making&nbsp;your&nbsp;information&nbsp;management&nbsp;more&nbsp;convenient&nbsp;and&nbsp;securing&nbsp;your&nbsp;information&nbsp;and&nbsp;communication.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;strictly&nbsp;comply&nbsp;with&nbsp;laws&nbsp;and&nbsp;regulations&nbsp;and&nbsp;follow&nbsp;the&nbsp;following&nbsp;privacy&nbsp;protection&nbsp;principles&nbsp;so&nbsp;that&nbsp;we&nbsp;will&nbsp;provide&nbsp;you&nbsp;with&nbsp;safer&nbsp;and&nbsp;more&nbsp;reliable&nbsp;services.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">&#8226;&nbsp;In&nbsp;order&nbsp;to&nbsp;provide&nbsp;you&nbsp;with&nbsp;game&nbsp;entertainment&nbsp;and&nbsp;related&nbsp;player&nbsp;interaction&nbsp;and&nbsp;consumption&nbsp;services,&nbsp;we&nbsp;need&nbsp;to&nbsp;collect&nbsp;your&nbsp;information&nbsp;such&nbsp;as&nbsp;game&nbsp;history,&nbsp;device&nbsp;information,&nbsp;login&nbsp;logs&nbsp;and&nbsp;so&nbsp;on.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">&#8226;&nbsp;In&nbsp;order&nbsp;to&nbsp;better&nbsp;protect&nbsp;the&nbsp;physical&nbsp;and&nbsp;mental&nbsp;health&nbsp;of&nbsp;minors&nbsp;and&nbsp;promote&nbsp;civilized&nbsp;Internet&nbsp;access&nbsp;for&nbsp;minors,&nbsp;we&nbsp;may&nbsp;implement&nbsp;more&nbsp;stringent&nbsp;anti-addiction&nbsp;measures&nbsp;on&nbsp;the&nbsp;basis&nbsp;of&nbsp;the&nbsp;provisions&nbsp;of&nbsp;the&nbsp;national&nbsp;policy&nbsp;on&nbsp;anti-addiction&nbsp;in&nbsp;terms&nbsp;of&nbsp;online&nbsp;games.&nbsp;Meanwhile,&nbsp;we&nbsp;have&nbsp;been&nbsp;constantly&nbsp;researching&nbsp;and&nbsp;testing&nbsp;different&nbsp;kinds&nbsp;of&nbsp;new&nbsp;technologies&nbsp;to&nbsp;protect&nbsp;minors.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">&#8226;&nbsp;You&nbsp;can&nbsp;manage&nbsp;your&nbsp;personal&nbsp;information&nbsp;and&nbsp;relevant&nbsp;authorization&nbsp;as&nbsp;described&nbsp;in&nbsp;the&nbsp;Privacy&nbsp;Policy.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">&#8226;&nbsp;We&nbsp;have&nbsp;applied&nbsp;multiple&nbsp;security&nbsp;measures&nbsp;to&nbsp;ensure&nbsp;that&nbsp;your&nbsp;personal&nbsp;information&nbsp;is&nbsp;protected&nbsp;at&nbsp;a&nbsp;reasonable&nbsp;level&nbsp;of&nbsp;security.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.&nbsp;Information&nbsp;Collected&nbsp;by&nbsp;Us</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">2.&nbsp;Information&nbsp;Storage</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.&nbsp;Information&nbsp;Security</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.&nbsp;How&nbsp;We&nbsp;Use&nbsp;the&nbsp;Information</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">5.&nbsp;External&nbsp;Availability</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">6.&nbsp;Your&nbsp;Rights</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">7.&nbsp;Changes</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">8.&nbsp;Protection&nbsp;of&nbsp;Minors</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">9.&nbsp;Others</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">10.&nbsp;Contact&nbsp;Us</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">1.Information&nbsp;Collected&nbsp;by&nbsp;Us</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">When&nbsp;you&nbsp;use&nbsp;the&nbsp;services&nbsp;provided&nbsp;by&nbsp;Evergreen,&nbsp;Evergreen&nbsp;will&nbsp;collect&nbsp;information&nbsp;that&nbsp;you&nbsp;voluntarily&nbsp;provide&nbsp;while&nbsp;using&nbsp;the&nbsp;services&nbsp;or&nbsp;that&nbsp;is&nbsp;produced&nbsp;as&nbsp;a&nbsp;result&nbsp;of&nbsp;your&nbsp;use&nbsp;of&nbsp;the&nbsp;services&nbsp;in&nbsp;order&nbsp;to&nbsp;provide&nbsp;you&nbsp;with&nbsp;our&nbsp;services&nbsp;and&nbsp;optimize&nbsp;them&nbsp;as&nbsp;well&nbsp;as&nbsp;secure&nbsp;your&nbsp;account&nbsp;in&nbsp;the&nbsp;following&nbsp;ways:</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.1&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">When&nbsp;you&nbsp;sign&nbsp;up&nbsp;for&nbsp;or&nbsp;use&nbsp;the&nbsp;services&nbsp;provided&nbsp;by&nbsp;Evergreen,&nbsp;we&nbsp;will&nbsp;collect&nbsp;your&nbsp;network&nbsp;identifier&nbsp;and&nbsp;personal&nbsp;commonly-used&nbsp;device&nbsp;information&nbsp;so&nbsp;as&nbsp;to&nbsp;mark&nbsp;you&nbsp;as&nbsp;a&nbsp;user&nbsp;of&nbsp;Evergreen.&nbsp;If&nbsp;you&nbsp;log&nbsp;in&nbsp;to&nbsp;Evergreen&nbsp;by&nbsp;using&nbsp;another&nbsp;account&nbsp;we&nbsp;approve&nbsp;(hereafter&nbsp;referred&nbsp;to&nbsp;as&nbsp;a&nbsp;&#8220;third-party&nbsp;account&#8221;)&nbsp;as&nbsp;your&nbsp;associated&nbsp;game&nbsp;account,&nbsp;We&nbsp;will&nbsp;collect&nbsp;unique&nbsp;identifiers,&nbsp;avatars,&nbsp;and&nbsp;nicknames&nbsp;from&nbsp;your&nbsp;email,&nbsp;Facebook&nbsp;account,&nbsp;Google&nbsp;account,&nbsp;and&nbsp;third-party&nbsp;accounts,&nbsp;which&nbsp;will&nbsp;be&nbsp;used&nbsp;to&nbsp;store&nbsp;your&nbsp;login&nbsp;information&nbsp;so&nbsp;that&nbsp;you&nbsp;can&nbsp;log&nbsp;in&nbsp;from&nbsp;different&nbsp;devices.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">If&nbsp;you&nbsp;log&nbsp;in&nbsp;to&nbsp;Evergreen&nbsp;by&nbsp;using&nbsp;email,Facebook,Google&nbsp;and/or&nbsp;a&nbsp;third-party&nbsp;account&nbsp;as&nbsp;an&nbsp;associated&nbsp;game&nbsp;account,&nbsp;in&nbsp;order&nbsp;to&nbsp;better&nbsp;provide&nbsp;you&nbsp;with&nbsp;game&nbsp;services&nbsp;and&nbsp;improve&nbsp;game&nbsp;experience,&nbsp;we&nbsp;will&nbsp;collect&nbsp;your&nbsp;unique&nbsp;ID,&nbsp;nickname,&nbsp;avatar,&nbsp;friend&nbsp;relationship&nbsp;involved&nbsp;in&nbsp;your&nbsp;Facebook&nbsp;account,&nbsp;Google&nbsp;account,&nbsp;and&nbsp;such&nbsp;third-party&nbsp;account,&nbsp;other&nbsp;information&nbsp;authorized&nbsp;by&nbsp;you&nbsp;as&nbsp;well&nbsp;as&nbsp;your&nbsp;relevant&nbsp;action&nbsp;and&nbsp;game&nbsp;information&nbsp;in&nbsp;Evergreen&nbsp;(including&nbsp;your&nbsp;login&nbsp;status,&nbsp;match&nbsp;information/status&nbsp;and&nbsp;achievement&nbsp;information),&nbsp;and&nbsp;may&nbsp;display&nbsp;to&nbsp;you,&nbsp;other&nbsp;users&nbsp;or&nbsp;your&nbsp;friends.&nbsp;Depending&nbsp;on&nbsp;the&nbsp;game&nbsp;products,&nbsp;we&nbsp;may&nbsp;provide&nbsp;you&nbsp;with&nbsp;relevant&nbsp;permissions&nbsp;so&nbsp;that&nbsp;you&nbsp;can&nbsp;set&nbsp;whether&nbsp;to&nbsp;display&nbsp;the&nbsp;aforementioned&nbsp;relevant&nbsp;information&nbsp;accordingly.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.2&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">In&nbsp;order&nbsp;to&nbsp;meet&nbsp;the&nbsp;requirements&nbsp;of&nbsp;relevant&nbsp;laws&nbsp;and&nbsp;regulations&nbsp;and&nbsp;relevant&nbsp;authorities,&nbsp;we&nbsp;will&nbsp;collect&nbsp;your&nbsp;identity&nbsp;information&nbsp;(including&nbsp;name,&nbsp;cell&nbsp;phone&nbsp;number,&nbsp;photo,&nbsp;etc.)&nbsp;with&nbsp;your&nbsp;consent&nbsp;or&nbsp;in&nbsp;case&nbsp;of&nbsp;your&nbsp;voluntary&nbsp;provision&nbsp;if&nbsp;Evergreen&#8217;s&nbsp;users&nbsp;need&nbsp;to&nbsp;use&nbsp;and&nbsp;enjoy&nbsp;Evergreen.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.3&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">In&nbsp;order&nbsp;to&nbsp;ensure&nbsp;your&nbsp;normal&nbsp;use&nbsp;of&nbsp;our&nbsp;services,&nbsp;maintain&nbsp;the&nbsp;normal&nbsp;operation&nbsp;of&nbsp;the&nbsp;basic&nbsp;functions&nbsp;of&nbsp;the&nbsp;game,&nbsp;optimize&nbsp;the&nbsp;performance&nbsp;of&nbsp;the&nbsp;game&nbsp;products,&nbsp;improve&nbsp;your&nbsp;game&nbsp;experience&nbsp;as&nbsp;well&nbsp;as&nbsp;secure&nbsp;your&nbsp;account,&nbsp;we&nbsp;will&nbsp;collect&nbsp;your&nbsp;information&nbsp;such&nbsp;as&nbsp;your&nbsp;device&nbsp;ID,&nbsp;device&nbsp;name,&nbsp;device&nbsp;type&nbsp;and&nbsp;version,&nbsp;system&nbsp;version,&nbsp;IP&nbsp;address,&nbsp;MAC&nbsp;address,&nbsp;application&nbsp;ID,&nbsp;network&nbsp;type&nbsp;and&nbsp;so&nbsp;on.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.4&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">When&nbsp;you&nbsp;use&nbsp;services&nbsp;provided&nbsp;by&nbsp;Evergreen,&nbsp;we&nbsp;will&nbsp;collect&nbsp;your&nbsp;game&nbsp;log&nbsp;information,&nbsp;such&nbsp;as&nbsp;log-in&nbsp;record,&nbsp;item&nbsp;log,&nbsp;game&nbsp;match&nbsp;information,&nbsp;and&nbsp;friendship&nbsp;record&nbsp;so&nbsp;that&nbsp;you&nbsp;can&nbsp;view&nbsp;your&nbsp;game&nbsp;history&nbsp;in&nbsp;the&nbsp;client,&nbsp;in&nbsp;addition,&nbsp;we&nbsp;can&nbsp;apply&nbsp;it&nbsp;to&nbsp;game&nbsp;operation&nbsp;analysis,&nbsp;customer&nbsp;service&nbsp;complaint&nbsp;handling&nbsp;and&nbsp;other&nbsp;game&nbsp;security&nbsp;analysis.&nbsp;What&#8217;s&nbsp;more,&nbsp;in&nbsp;order&nbsp;to&nbsp;improve&nbsp;your&nbsp;game&nbsp;experience,&nbsp;we&nbsp;may&nbsp;synchronize&nbsp;the&nbsp;aforementioned&nbsp;information&nbsp;to&nbsp;the&nbsp;subsequent&nbsp;versions&nbsp;of&nbsp;the&nbsp;game&nbsp;or&nbsp;to&nbsp;our&nbsp;other&nbsp;products&nbsp;you&nbsp;are&nbsp;using&nbsp;under&nbsp;your&nbsp;authorization.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.5&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">If&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">you&nbsp;are&nbsp;using&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">SAMSUNG
				<font face="宋体">，</font>
				<font face="Times New Roman">XIAOMI</font>
				<font face="宋体">，</font>
				<font face="Times New Roman">HUAWEI,&nbsp;GOOGLE&nbsp;and/or</font>
			</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;other&nbsp;brands&nbsp;of&nbsp;cell&nbsp;phones,&nbsp;Evergreen&#8217;s&nbsp;Push&nbsp;SDK&nbsp;for&nbsp;these&nbsp;cell&nbsp;phone&nbsp;manufacturers&nbsp;will&nbsp;require&nbsp;the&nbsp;collection&nbsp;of&nbsp;unique&nbsp;cell&nbsp;phone&nbsp;identification&nbsp;information&nbsp;(e.g.&nbsp;IMEI)&nbsp;and&nbsp;may&nbsp;collect&nbsp;your&nbsp;cell&nbsp;phone&nbsp;model,&nbsp;system&nbsp;type,&nbsp;system&nbsp;version,&nbsp;device&nbsp;screen&nbsp;size&nbsp;and&nbsp;other&nbsp;parameters&nbsp;for&nbsp;pushing&nbsp;information&nbsp;about&nbsp;Evergreen&nbsp;products&nbsp;and&nbsp;events.&nbsp;Please&nbsp;refer&nbsp;to&nbsp;the&nbsp;SDK&nbsp;operator&#8217;s&nbsp;privacy&nbsp;policy&nbsp;or&nbsp;related&nbsp;statements&nbsp;for&nbsp;details.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.6&nbsp;&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">When&nbsp;you&nbsp;use&nbsp;the&nbsp;consumption&nbsp;features&nbsp;of&nbsp;&nbsp;Evergreen&nbsp;products,&nbsp;we&nbsp;will&nbsp;collect&nbsp;your&nbsp;information&nbsp;about&nbsp;Consumption&nbsp;Record&nbsp;so&nbsp;that&nbsp;you&nbsp;can&nbsp;check&nbsp;your&nbsp;transaction&nbsp;history&nbsp;and&nbsp;secure&nbsp;your&nbsp;virtual&nbsp;goods&nbsp;to&nbsp;the&nbsp;greatest&nbsp;extent.&nbsp;Consumption&nbsp;Record&nbsp;is&nbsp;sensitive&nbsp;information&nbsp;which&nbsp;is&nbsp;necessary&nbsp;to&nbsp;enable&nbsp;the&nbsp;consumption&nbsp;features&nbsp;of&nbsp;Evergreen&nbsp;products.&nbsp;Transactions&nbsp;cannot&nbsp;be&nbsp;completed&nbsp;without&nbsp;it.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.7&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">In&nbsp;order&nbsp;to&nbsp;secure&nbsp;your&nbsp;game&nbsp;account&nbsp;and&nbsp;create&nbsp;a&nbsp;fair,&nbsp;healthy&nbsp;and&nbsp;secure&nbsp;game&nbsp;environment,&nbsp;we&nbsp;will&nbsp;collect&nbsp;your&nbsp;game&nbsp;identification&nbsp;information,&nbsp;hardware&nbsp;and&nbsp;operating&nbsp;system&nbsp;information,&nbsp;process&nbsp;and&nbsp;game&nbsp;crash&nbsp;records&nbsp;and&nbsp;so&nbsp;on&nbsp;so&nbsp;as&nbsp;to&nbsp;prevent&nbsp;behaviors&nbsp;that&nbsp;undermine&nbsp;the&nbsp;fair&nbsp;game&nbsp;environment&nbsp;or&nbsp;interfere&nbsp;with&nbsp;or&nbsp;disrupt&nbsp;the&nbsp;normal&nbsp;operation&nbsp;of&nbsp;the&nbsp;game&nbsp;services&nbsp;(such&nbsp;as&nbsp;for&nbsp;detecting&nbsp;piracy,&nbsp;scanning&nbsp;for&nbsp;plug-ins,&nbsp;preventing&nbsp;cheating,&nbsp;etc.).</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.8&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">When&nbsp;you&nbsp;interact&nbsp;with&nbsp;other&nbsp;players&nbsp;in&nbsp;the&nbsp;game&nbsp;through&nbsp;text,&nbsp;pictures&nbsp;and&nbsp;other&nbsp;means,&nbsp;we&nbsp;may&nbsp;collect&nbsp;and&nbsp;save&nbsp;the&nbsp;content&nbsp;of&nbsp;the&nbsp;above&nbsp;information&nbsp;you&nbsp;send&nbsp;for&nbsp;the&nbsp;purpose&nbsp;of&nbsp;filtering&nbsp;pornography,&nbsp;violence,&nbsp;politics,&nbsp;abusive&nbsp;language,&nbsp;malicious&nbsp;advertising&nbsp;and&nbsp;other&nbsp;inappropriate&nbsp;contents&nbsp;so&nbsp;as&nbsp;to&nbsp;purify&nbsp;the&nbsp;game&nbsp;environment&nbsp;and&nbsp;maintain&nbsp;a&nbsp;healthy&nbsp;Internet&nbsp;environment.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.9&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">If&nbsp;you&nbsp;wish&nbsp;to&nbsp;interact&nbsp;with&nbsp;other&nbsp;game&nbsp;players&nbsp;through&nbsp;voice&nbsp;and&nbsp;video,&nbsp;the&nbsp;game&nbsp;will&nbsp;access&nbsp;your&nbsp;microphone&nbsp;and&nbsp;camera&nbsp;under&nbsp;your&nbsp;authorization&nbsp;to&nbsp;provide&nbsp;you&nbsp;with&nbsp;voice&nbsp;chat&nbsp;and&nbsp;other&nbsp;functions.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.10&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">If&nbsp;you&nbsp;want&nbsp;to&nbsp;interact&nbsp;with&nbsp;nearby&nbsp;game&nbsp;players,&nbsp;we&nbsp;will&nbsp;collect&nbsp;your&nbsp;Geolocation&nbsp;Information&nbsp;under&nbsp;your&nbsp;authorization&nbsp;for&nbsp;the&nbsp;purpose&nbsp;of&nbsp;looking&nbsp;for&nbsp;nearby&nbsp;game&nbsp;players&nbsp;so&nbsp;that&nbsp;you&nbsp;can&nbsp;match&nbsp;and&nbsp;form&nbsp;teams&nbsp;with&nbsp;them.&nbsp;Geolocation&nbsp;Information&nbsp;is&nbsp;sensitive&nbsp;information.&nbsp;Refusal&nbsp;to&nbsp;provide&nbsp;it&nbsp;will&nbsp;only&nbsp;prevent&nbsp;you&nbsp;from&nbsp;interacting&nbsp;with&nbsp;nearby&nbsp;game&nbsp;players&nbsp;rather&nbsp;than&nbsp;affect&nbsp;your&nbsp;use&nbsp;of&nbsp;other&nbsp;features&nbsp;of&nbsp;Evergreen,&nbsp;and&nbsp;you&nbsp;may&nbsp;revoke&nbsp;your&nbsp;geolocation&nbsp;authorization&nbsp;at&nbsp;any&nbsp;time.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.11&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;have&nbsp;enabled&nbsp;moderate&nbsp;gaming&nbsp;alerts.&nbsp;If&nbsp;you&nbsp;are&nbsp;using&nbsp;a&nbsp;wearable&nbsp;device,&nbsp;we&nbsp;may&nbsp;collect&nbsp;information&nbsp;such&nbsp;as&nbsp;your&nbsp;online&nbsp;gaming&nbsp;heartbeat&nbsp;with&nbsp;your&nbsp;consent&nbsp;so&nbsp;as&nbsp;to&nbsp;send&nbsp;the&nbsp;alert&nbsp;when&nbsp;you&nbsp;are&nbsp;playing&nbsp;excessively.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.12&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;may&nbsp;analyze&nbsp;your&nbsp;gaming&nbsp;data&nbsp;to&nbsp;understand&nbsp;your&nbsp;preferences&nbsp;and&nbsp;may&nbsp;share&nbsp;such&nbsp;information&nbsp;and&nbsp;other&nbsp;necessary&nbsp;information&nbsp;to&nbsp;other&nbsp;products&nbsp;or&nbsp;services&nbsp;within&nbsp;Caohua&nbsp;Group&nbsp;so&nbsp;that&nbsp;we&nbsp;can&nbsp;better&nbsp;show&nbsp;you&nbsp;contents&nbsp;that&nbsp;may&nbsp;attract&nbsp;your&nbsp;interest&nbsp;in&nbsp;other&nbsp;products&nbsp;or&nbsp;services&nbsp;of&nbsp;Evergreen&nbsp;and&nbsp;Caohua&nbsp;Group.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.13&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">In&nbsp;accordance&nbsp;with&nbsp;relevant&nbsp;laws,&nbsp;regulations&nbsp;and&nbsp;national&nbsp;standards,&nbsp;we&nbsp;may&nbsp;collect&nbsp;and&nbsp;use&nbsp;your&nbsp;personal&nbsp;information&nbsp;without&nbsp;your&nbsp;authorization&nbsp;in&nbsp;the&nbsp;following&nbsp;cases:</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">1)&nbsp;Directly&nbsp;related&nbsp;to&nbsp;national&nbsp;security,&nbsp;national&nbsp;defense&nbsp;security&nbsp;and&nbsp;other&nbsp;national&nbsp;interests;&nbsp;directly&nbsp;related&nbsp;to&nbsp;public&nbsp;security,&nbsp;public&nbsp;health,&nbsp;public&nbsp;information&nbsp;and&nbsp;other&nbsp;significant&nbsp;public&nbsp;interests;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">2)&nbsp;Directly&nbsp;related&nbsp;to&nbsp;crime&nbsp;investigation,&nbsp;prosecution,&nbsp;trial&nbsp;and&nbsp;execution&nbsp;of&nbsp;judgments,&nbsp;etc.;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">3)&nbsp;For&nbsp;the&nbsp;purpose&nbsp;of&nbsp;safeguarding&nbsp;your&nbsp;or&nbsp;other&nbsp;individuals&#8217;&nbsp;life,&nbsp;property,&nbsp;reputation&nbsp;and&nbsp;other&nbsp;significant&nbsp;legitimate&nbsp;rights&nbsp;and&nbsp;interests&nbsp;but&nbsp;it&nbsp;is&nbsp;difficult&nbsp;to&nbsp;obtain&nbsp;your&nbsp;consent;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">4)&nbsp;The&nbsp;personal&nbsp;information&nbsp;collected&nbsp;is&nbsp;disclosed&nbsp;to&nbsp;the&nbsp;public&nbsp;by&nbsp;you.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">5)&nbsp;Personal&nbsp;information&nbsp;is&nbsp;collected&nbsp;from&nbsp;information&nbsp;that&nbsp;is&nbsp;lawfully&nbsp;and&nbsp;publicly&nbsp;disclosed,&nbsp;such&nbsp;as&nbsp;legal&nbsp;news&nbsp;reports,&nbsp;government&nbsp;information&nbsp;disclosure&nbsp;and&nbsp;other&nbsp;channels;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">6)&nbsp;It&nbsp;is&nbsp;necessary&nbsp;to&nbsp;enter&nbsp;into&nbsp;and&nbsp;perform&nbsp;a&nbsp;contract&nbsp;at&nbsp;your&nbsp;demand;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">7)&nbsp;It&nbsp;is&nbsp;necessary&nbsp;for&nbsp;maintaining&nbsp;the&nbsp;safe&nbsp;and&nbsp;stable&nbsp;operation&nbsp;of&nbsp;the&nbsp;products&nbsp;or&nbsp;services&nbsp;provided,&nbsp;such&nbsp;as&nbsp;discovering&nbsp;and&nbsp;handling&nbsp;product&nbsp;or&nbsp;service&nbsp;failures;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">8)&nbsp;It&nbsp;is&nbsp;necessary&nbsp;to&nbsp;carry&nbsp;out&nbsp;legal&nbsp;news&nbsp;reporting;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">9)&nbsp;It&nbsp;is&nbsp;necessary&nbsp;to&nbsp;conduct&nbsp;statistical&nbsp;or&nbsp;academic&nbsp;research&nbsp;for&nbsp;the&nbsp;interest&nbsp;of&nbsp;the&nbsp;public,&nbsp;and&nbsp;the&nbsp;personal&nbsp;information&nbsp;contained&nbsp;in&nbsp;the&nbsp;results&nbsp;is&nbsp;de-identified&nbsp;when&nbsp;the&nbsp;results&nbsp;of&nbsp;academic&nbsp;research&nbsp;or&nbsp;descriptions&nbsp;are&nbsp;provided&nbsp;to&nbsp;the&nbsp;public;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">10)&nbsp;Other&nbsp;cases&nbsp;as&nbsp;provided&nbsp;by&nbsp;law&nbsp;and&nbsp;regulations.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">1.14&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">Please&nbsp;understand&nbsp;that&nbsp;the&nbsp;features&nbsp;and&nbsp;services&nbsp;we&nbsp;provide&nbsp;to&nbsp;you&nbsp;are&nbsp;constantly&nbsp;updated&nbsp;and&nbsp;developed,&nbsp;and&nbsp;if&nbsp;a&nbsp;certain&nbsp;feature&nbsp;or&nbsp;service&nbsp;is&nbsp;not&nbsp;contained&nbsp;in&nbsp;the&nbsp;foregoing&nbsp;description&nbsp;and&nbsp;your&nbsp;information&nbsp;is&nbsp;collected,&nbsp;we&nbsp;will&nbsp;separately&nbsp;tell&nbsp;you&nbsp;the&nbsp;content,&nbsp;scope&nbsp;and&nbsp;purpose&nbsp;of&nbsp;information&nbsp;collection&nbsp;through&nbsp;page&nbsp;prompts,&nbsp;interactive&nbsp;processes,&nbsp;website&nbsp;announcements,&nbsp;etc.&nbsp;in&nbsp;order&nbsp;to&nbsp;obtain&nbsp;your&nbsp;consent.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">Presently,&nbsp;we&nbsp;will&nbsp;not&nbsp;actively&nbsp;obtain&nbsp;your&nbsp;personal&nbsp;information&nbsp;from&nbsp;any&nbsp;third&nbsp;party&nbsp;other&nbsp;than&nbsp;Caohua&nbsp;Group,&nbsp;except&nbsp;that&nbsp;game&nbsp;research&nbsp;information&nbsp;is&nbsp;collected&nbsp;from&nbsp;a&nbsp;third&nbsp;party&nbsp;research&nbsp;organization&nbsp;to&nbsp;help&nbsp;us&nbsp;improve&nbsp;our&nbsp;game&nbsp;products&nbsp;and&nbsp;provide&nbsp;personalized&nbsp;services&nbsp;and&nbsp;in&nbsp;cases&nbsp;where&nbsp;you&nbsp;log&nbsp;in&nbsp;to&nbsp;Evergreen&nbsp;by&nbsp;using&nbsp;a&nbsp;third-party&nbsp;account&nbsp;as&nbsp;described&nbsp;in&nbsp;Article&nbsp;1.1&nbsp;of&nbsp;the&nbsp;Privacy&nbsp;Policy.&nbsp;Where&nbsp;we&nbsp;need&nbsp;to&nbsp;obtain&nbsp;and&nbsp;process&nbsp;your&nbsp;personal&nbsp;information&nbsp;indirectly&nbsp;from&nbsp;a&nbsp;third&nbsp;party&nbsp;other&nbsp;than&nbsp;Caohua&nbsp;Group&nbsp;for&nbsp;the&nbsp;purpose&nbsp;of&nbsp;business&nbsp;development,&nbsp;we&nbsp;will&nbsp;strictly&nbsp;comply&nbsp;with&nbsp;the&nbsp;provisions&nbsp;of&nbsp;relevant&nbsp;laws&nbsp;and&nbsp;regulations,&nbsp;require&nbsp;such&nbsp;third&nbsp;party&nbsp;to&nbsp;obtain&nbsp;your&nbsp;consent&nbsp;and&nbsp;ensure&nbsp;the&nbsp;legality&nbsp;of&nbsp;the&nbsp;information&nbsp;provided&nbsp;by&nbsp;it.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">2.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Information&nbsp;Storage</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">2.1.&nbsp;Ways&nbsp;and&nbsp;Duration&nbsp;of&nbsp;Information&nbsp;Storage</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;will&nbsp;store&nbsp;your&nbsp;information&nbsp;in&nbsp;secure&nbsp;ways,&nbsp;including&nbsp;local&nbsp;storage&nbsp;(e.g.&nbsp;data&nbsp;caching&nbsp;by&nbsp;using&nbsp;APPs),&nbsp;databases&nbsp;and&nbsp;server&nbsp;logs.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">In&nbsp;general,&nbsp;we&nbsp;will&nbsp;only&nbsp;store&nbsp;your&nbsp;personal&nbsp;information&nbsp;within&nbsp;the&nbsp;period&nbsp;(180&nbsp;days)&nbsp;necessary&nbsp;to&nbsp;perform&nbsp;services&nbsp;or&nbsp;under&nbsp;the&nbsp;conditions&nbsp;as&nbsp;specified&nbsp;by&nbsp;laws&nbsp;and&nbsp;regulations.&nbsp;In&nbsp;case&nbsp;of&nbsp;special&nbsp;circumstances&nbsp;where&nbsp;storage&nbsp;for&nbsp;an&nbsp;extended&nbsp;period&nbsp;of&nbsp;time&nbsp;is&nbsp;required,&nbsp;we&nbsp;will&nbsp;explain&nbsp;to&nbsp;you&nbsp;the&nbsp;specific&nbsp;circumstances&nbsp;and&nbsp;period&nbsp;of&nbsp;overtime&nbsp;storage&nbsp;in&nbsp;detail.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">2.2&nbsp;Information&nbsp;Storage&nbsp;Region</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;will&nbsp;store&nbsp;the&nbsp;collected&nbsp;personal&nbsp;information&nbsp;within&nbsp;the&nbsp;territory&nbsp;of&nbsp;China&nbsp;in&nbsp;accordance&nbsp;with&nbsp;the&nbsp;laws&nbsp;and&nbsp;regulations.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">2.3&nbsp;Notification&nbsp;in&nbsp;case&nbsp;of&nbsp;Discontinuation&nbsp;of&nbsp;Products&nbsp;or&nbsp;Services</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">In&nbsp;case&nbsp;of&nbsp;discontinuation&nbsp;of&nbsp;our&nbsp;products&nbsp;or&nbsp;services,&nbsp;we&nbsp;will&nbsp;notify&nbsp;you&nbsp;by&nbsp;announcement&nbsp;in&nbsp;accordance&nbsp;with&nbsp;relevant&nbsp;laws&nbsp;and&nbsp;regulations,&nbsp;and&nbsp;protect&nbsp;your&nbsp;legal&nbsp;rights&nbsp;and&nbsp;interests&nbsp;as&nbsp;per&nbsp;law.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">3.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Information&nbsp;Security</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.1&nbsp;Security&nbsp;Measures</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;strive&nbsp;to&nbsp;secure&nbsp;the&nbsp;user&nbsp;information&nbsp;to&nbsp;prevent&nbsp;its&nbsp;leakage,&nbsp;loss,&nbsp;improper&nbsp;use,&nbsp;unauthorized&nbsp;access&nbsp;and&nbsp;disclosure.&nbsp;We&nbsp;have&nbsp;applied&nbsp;multiple&nbsp;security&nbsp;measures&nbsp;to&nbsp;ensure&nbsp;that&nbsp;users&#8217;&nbsp;personal&nbsp;information&nbsp;is&nbsp;protected&nbsp;at&nbsp;a&nbsp;reasonable&nbsp;level&nbsp;of&nbsp;security,&nbsp;including&nbsp;technical&nbsp;protection&nbsp;means,&nbsp;management&nbsp;system&nbsp;control&nbsp;and&nbsp;security&nbsp;system&nbsp;protection&nbsp;and&nbsp;so&nbsp;on.&nbsp;Besides,&nbsp;the&nbsp;relevant&nbsp;systems&nbsp;of&nbsp;our&nbsp;games&nbsp;have&nbsp;passed&nbsp;the&nbsp;filing&nbsp;and&nbsp;evaluation&nbsp;of&nbsp;national&nbsp;network&nbsp;security&nbsp;level&nbsp;protection&nbsp;(level&nbsp;3).</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;adopt&nbsp;the&nbsp;industry-leading&nbsp;technical&nbsp;protection&nbsp;measures.&nbsp;Our&nbsp;technical&nbsp;means&nbsp;include&nbsp;but&nbsp;are&nbsp;not&nbsp;limited&nbsp;to&nbsp;firewalls,&nbsp;encryption&nbsp;(e.g.&nbsp;SSL),&nbsp;de-identification&nbsp;or&nbsp;anonymization&nbsp;processing,&nbsp;access&nbsp;control&nbsp;measures&nbsp;and&nbsp;so&nbsp;on.&nbsp;In&nbsp;addition,&nbsp;we&nbsp;will&nbsp;continually&nbsp;enhance&nbsp;the&nbsp;security&nbsp;capability&nbsp;of&nbsp;the&nbsp;software&nbsp;installed&nbsp;on&nbsp;your&nbsp;device.&nbsp;For&nbsp;example,&nbsp;we&nbsp;complete&nbsp;partial&nbsp;encryption&nbsp;of&nbsp;information&nbsp;locally&nbsp;on&nbsp;your&nbsp;device&nbsp;to&nbsp;reinforce&nbsp;secure&nbsp;transmission;&nbsp;and&nbsp;we&nbsp;learn&nbsp;information&nbsp;about&nbsp;applications&nbsp;installed&nbsp;on&nbsp;your&nbsp;device&nbsp;and&nbsp;information&nbsp;about&nbsp;running&nbsp;processes&nbsp;to&nbsp;prevent&nbsp;viruses,&nbsp;Trojans&nbsp;and&nbsp;other&nbsp;malicious&nbsp;programs.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;have&nbsp;established&nbsp;special&nbsp;management&nbsp;systems,&nbsp;processes&nbsp;and&nbsp;organizations&nbsp;for&nbsp;safeguarding&nbsp;personal&nbsp;information&nbsp;security.&nbsp;For&nbsp;example,&nbsp;we&nbsp;strictly&nbsp;limit&nbsp;the&nbsp;scope&nbsp;of&nbsp;personnel&nbsp;who&nbsp;access&nbsp;information,&nbsp;and&nbsp;require&nbsp;them&nbsp;to&nbsp;comply&nbsp;with&nbsp;confidentiality&nbsp;obligations&nbsp;and&nbsp;conduct&nbsp;audits,&nbsp;and&nbsp;those&nbsp;who&nbsp;violate&nbsp;their&nbsp;obligations&nbsp;will&nbsp;be&nbsp;punished&nbsp;according&nbsp;to&nbsp;regulations.&nbsp;We&nbsp;also&nbsp;review&nbsp;the&nbsp;management&nbsp;system,&nbsp;processes&nbsp;and&nbsp;organizations&nbsp;to&nbsp;prevent&nbsp;unauthorized&nbsp;access,&nbsp;use&nbsp;or&nbsp;disclosure&nbsp;of&nbsp;users&#8217;&nbsp;information&nbsp;by&nbsp;unauthorized&nbsp;persons.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;recommend&nbsp;that&nbsp;you&nbsp;should&nbsp;pay&nbsp;enough&nbsp;attention&nbsp;to&nbsp;the&nbsp;protection&nbsp;of&nbsp;your&nbsp;personal&nbsp;information&nbsp;when&nbsp;using&nbsp;our&nbsp;products&nbsp;and&nbsp;services,&nbsp;and&nbsp;we&nbsp;will&nbsp;provide&nbsp;a&nbsp;variety&nbsp;of&nbsp;security&nbsp;features&nbsp;to&nbsp;assist&nbsp;you&nbsp;in&nbsp;securing&nbsp;your&nbsp;personal&nbsp;information.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">3.2&nbsp;Security&nbsp;Incident&nbsp;Handling&nbsp;Measures</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">In&nbsp;the&nbsp;event&nbsp;of&nbsp;a&nbsp;security&nbsp;incident&nbsp;such&nbsp;as&nbsp;personal&nbsp;information&nbsp;leakage,&nbsp;we&nbsp;will&nbsp;initiate&nbsp;an&nbsp;emergency&nbsp;plan&nbsp;to&nbsp;prevent&nbsp;its&nbsp;expansion.&nbsp;After&nbsp;a&nbsp;security&nbsp;incident&nbsp;occurs,&nbsp;we&nbsp;will&nbsp;inform&nbsp;you&nbsp;of&nbsp;the&nbsp;basic&nbsp;situation&nbsp;of&nbsp;the&nbsp;security&nbsp;incident,&nbsp;the&nbsp;handling&nbsp;measures&nbsp;and&nbsp;remedial&nbsp;measures&nbsp;we&nbsp;will&nbsp;take&nbsp;or&nbsp;have&nbsp;taken,&nbsp;as&nbsp;well&nbsp;as&nbsp;our&nbsp;suggestions&nbsp;to&nbsp;you&nbsp;in&nbsp;response&nbsp;by&nbsp;announcements,&nbsp;push&nbsp;notifications&nbsp;or&nbsp;emails.&nbsp;In&nbsp;case&nbsp;of&nbsp;difficulty&nbsp;in&nbsp;informing&nbsp;you&nbsp;individually,&nbsp;we&nbsp;will&nbsp;issue&nbsp;warnings&nbsp;by&nbsp;announcements&nbsp;and&nbsp;other&nbsp;means.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">4.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">How&nbsp;We&nbsp;Use&nbsp;the&nbsp;Information</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;strictly&nbsp;comply&nbsp;with&nbsp;the&nbsp;provisions&nbsp;of&nbsp;laws&nbsp;and&nbsp;regulations&nbsp;and&nbsp;agreements&nbsp;with&nbsp;users,&nbsp;and&nbsp;use&nbsp;the&nbsp;collected&nbsp;information&nbsp;as&nbsp;described&nbsp;in&nbsp;the&nbsp;Privacy&nbsp;Policy&nbsp;in&nbsp;order&nbsp;to&nbsp;provide&nbsp;you&nbsp;with&nbsp;better&nbsp;services.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.1&nbsp;Rules&nbsp;for&nbsp;Use&nbsp;of&nbsp;Information&nbsp;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;will&nbsp;use&nbsp;the&nbsp;collected&nbsp;information&nbsp;in&nbsp;accordance&nbsp;with&nbsp;the&nbsp;following&nbsp;rules:</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">1)&nbsp;We&nbsp;will&nbsp;provide&nbsp;you&nbsp;with&nbsp;various&nbsp;features&nbsp;and&nbsp;services&nbsp;based&nbsp;on&nbsp;the&nbsp;information&nbsp;we&nbsp;collect,&nbsp;including&nbsp;basic&nbsp;game&nbsp;features,&nbsp;player&nbsp;interaction&nbsp;features,&nbsp;live&nbsp;streaming&nbsp;features&nbsp;and&nbsp;consumption&nbsp;features;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">2)&nbsp;We&nbsp;will&nbsp;analyze&nbsp;the&nbsp;operation&nbsp;of&nbsp;our&nbsp;products&nbsp;based&nbsp;on&nbsp;your&nbsp;frequency&nbsp;and&nbsp;situation&nbsp;of&nbsp;using&nbsp;Evergreen&nbsp;products,&nbsp;fault&nbsp;information,&nbsp;performance&nbsp;information,&nbsp;etc.&nbsp;In&nbsp;order&nbsp;to&nbsp;ensure&nbsp;the&nbsp;security&nbsp;of&nbsp;our&nbsp;services,&nbsp;optimize&nbsp;our&nbsp;products&nbsp;and&nbsp;improve&nbsp;our&nbsp;service&nbsp;quality.&nbsp;We&nbsp;will&nbsp;not&nbsp;combine&nbsp;the&nbsp;information&nbsp;we&nbsp;store&nbsp;in&nbsp;our&nbsp;analytics&nbsp;software&nbsp;with&nbsp;personally&nbsp;identifiable&nbsp;information&nbsp;provided&nbsp;by&nbsp;you.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">4.2&nbsp;Ways&nbsp;of&nbsp;Obtaining&nbsp;Consent&nbsp;after&nbsp;Informing&nbsp;the&nbsp;Purpose&nbsp;of&nbsp;Change</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;will&nbsp;use&nbsp;the&nbsp;collected&nbsp;information&nbsp;for&nbsp;the&nbsp;purposes&nbsp;covered&nbsp;by&nbsp;the&nbsp;Privacy&nbsp;Policy.&nbsp;If&nbsp;we&nbsp;use&nbsp;your&nbsp;personal&nbsp;information&nbsp;beyond&nbsp;the&nbsp;purposes&nbsp;for&nbsp;which&nbsp;it&nbsp;is&nbsp;collected&nbsp;and&nbsp;which&nbsp;are&nbsp;directly&nbsp;or&nbsp;reasonably&nbsp;related&nbsp;to&nbsp;such&nbsp;purposes,&nbsp;we&nbsp;will&nbsp;inform&nbsp;you&nbsp;again&nbsp;for&nbsp;your&nbsp;express&nbsp;consent&nbsp;before&nbsp;use.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">5.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">External&nbsp;Availability</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">Presently,&nbsp;we&nbsp;will&nbsp;not&nbsp;voluntarily&nbsp;share,&nbsp;provide&nbsp;or&nbsp;transfer&nbsp;your&nbsp;personal&nbsp;information&nbsp;to&nbsp;any&nbsp;third-party&nbsp;outside&nbsp;of&nbsp;other&nbsp;than&nbsp;Evergreen&nbsp;except&nbsp;as&nbsp;set&nbsp;forth&nbsp;in&nbsp;the&nbsp;Privacy&nbsp;Policy&nbsp;Guidelines&nbsp;and&nbsp;the&nbsp;User&nbsp;Agreement.&nbsp;In&nbsp;the&nbsp;event&nbsp;that&nbsp;your&nbsp;personal&nbsp;information&nbsp;is&nbsp;otherwise&nbsp;shared,&nbsp;provided&nbsp;or&nbsp;transferred&nbsp;or&nbsp;that&nbsp;you&nbsp;require&nbsp;us&nbsp;to&nbsp;share,&nbsp;provide&nbsp;or&nbsp;transfer&nbsp;your&nbsp;personal&nbsp;information&nbsp;to&nbsp;a&nbsp;third&nbsp;party&nbsp;other&nbsp;than&nbsp;Evergreen,&nbsp;we&nbsp;will&nbsp;directly&nbsp;obtain&nbsp;or&nbsp;confirm&nbsp;that&nbsp;such&nbsp;third&nbsp;party&nbsp;has&nbsp;obtained&nbsp;your&nbsp;express&nbsp;consent&nbsp;to&nbsp;the&nbsp;actions&nbsp;mentioned&nbsp;above.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">In&nbsp;order&nbsp;to&nbsp;improve&nbsp;your&nbsp;user&nbsp;experience,&nbsp;such&nbsp;as&nbsp;optimization&nbsp;of&nbsp;the&nbsp;effectiveness&nbsp;of&nbsp;ads&nbsp;(you&nbsp;may&nbsp;learn&nbsp;about&nbsp;and&nbsp;manage&nbsp;the&nbsp;ads&nbsp;displayed&nbsp;for&nbsp;you&nbsp;on&nbsp;the&nbsp;page&nbsp;of&nbsp;About&nbsp;Ads),&nbsp;we&nbsp;may&nbsp;need&nbsp;to&nbsp;share&nbsp;anonymized&nbsp;or&nbsp;de-identified&nbsp;information&nbsp;with&nbsp;third-party&nbsp;partners,&nbsp;etc.,&nbsp;and&nbsp;we&nbsp;will&nbsp;require&nbsp;them&nbsp;to&nbsp;strictly&nbsp;comply&nbsp;with&nbsp;our&nbsp;data&nbsp;privacy&nbsp;protection&nbsp;measures&nbsp;and&nbsp;requirements,&nbsp;including&nbsp;but&nbsp;not&nbsp;limited&nbsp;to&nbsp;processing&nbsp;in&nbsp;accordance&nbsp;with&nbsp;data&nbsp;protection&nbsp;agreements,&nbsp;letters&nbsp;of&nbsp;undertaking&nbsp;and&nbsp;related&nbsp;data&nbsp;processing&nbsp;policies&nbsp;so&nbsp;as&nbsp;to&nbsp;avoid&nbsp;identifying&nbsp;personal&nbsp;identity&nbsp;and&nbsp;safeguard&nbsp;privacy.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;will&nbsp;not&nbsp;share&nbsp;personally&nbsp;identifiable&nbsp;information&nbsp;(such&nbsp;as&nbsp;your&nbsp;name&nbsp;or&nbsp;email&nbsp;address)&nbsp;with&nbsp;our&nbsp;partners&nbsp;unless&nbsp;you&nbsp;expressly&nbsp;authorize&nbsp;us&nbsp;to&nbsp;do&nbsp;so.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;will&nbsp;not&nbsp;publicly&nbsp;disclose&nbsp;the&nbsp;collected&nbsp;personal&nbsp;information&nbsp;except&nbsp;as&nbsp;otherwise&nbsp;provided&nbsp;in&nbsp;the&nbsp;Privacy&nbsp;Policy&nbsp;and&nbsp;User&nbsp;Agreement.&nbsp;If&nbsp;public&nbsp;disclosure&nbsp;is&nbsp;required,&nbsp;we&nbsp;will&nbsp;inform&nbsp;you&nbsp;of&nbsp;the&nbsp;purpose&nbsp;of&nbsp;such&nbsp;public&nbsp;disclosure,&nbsp;the&nbsp;type&nbsp;of&nbsp;information&nbsp;to&nbsp;be&nbsp;disclosed&nbsp;and&nbsp;possible&nbsp;sensitive&nbsp;information&nbsp;involved&nbsp;for&nbsp;your&nbsp;express&nbsp;consent.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">With&nbsp;the&nbsp;continuous&nbsp;development&nbsp;of&nbsp;our&nbsp;business,&nbsp;we&nbsp;may&nbsp;engage&nbsp;in&nbsp;transactions&nbsp;such&nbsp;as&nbsp;mergers,&nbsp;acquisitions&nbsp;and&nbsp;asset&nbsp;transfers,&nbsp;etc.,&nbsp;and&nbsp;we&nbsp;will&nbsp;inform&nbsp;you&nbsp;of&nbsp;relevant&nbsp;circumstances&nbsp;and&nbsp;continue&nbsp;to&nbsp;protect&nbsp;or&nbsp;require&nbsp;new&nbsp;controllers&nbsp;to&nbsp;continue&nbsp;to&nbsp;protect&nbsp;your&nbsp;personal&nbsp;information&nbsp;in&nbsp;accordance&nbsp;with&nbsp;laws,&nbsp;regulations&nbsp;and&nbsp;standards&nbsp;no&nbsp;less&nbsp;stringent&nbsp;than&nbsp;those&nbsp;required&nbsp;by&nbsp;the&nbsp;Privacy&nbsp;Policy.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">In&nbsp;addition,&nbsp;in&nbsp;accordance&nbsp;with&nbsp;relevant&nbsp;laws,&nbsp;regulations&nbsp;and&nbsp;national&nbsp;standards,&nbsp;we&nbsp;may&nbsp;share,&nbsp;transfer&nbsp;and&nbsp;publicly&nbsp;disclose&nbsp;your&nbsp;personal&nbsp;information&nbsp;without&nbsp;your&nbsp;authorization&nbsp;or&nbsp;content&nbsp;in&nbsp;advance&nbsp;in&nbsp;the&nbsp;following&nbsp;cases:</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">1)&nbsp;Directly&nbsp;related&nbsp;to&nbsp;national&nbsp;security&nbsp;and&nbsp;national&nbsp;defense&nbsp;security;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">2)&nbsp;Directly&nbsp;related&nbsp;to&nbsp;public&nbsp;security,&nbsp;public&nbsp;health&nbsp;and&nbsp;significant&nbsp;public&nbsp;interest;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">3)&nbsp;Directly&nbsp;related&nbsp;to&nbsp;crime&nbsp;investigation,&nbsp;prosecution,&nbsp;trial&nbsp;and&nbsp;execution&nbsp;of&nbsp;judgments,&nbsp;etc.;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">4)&nbsp;For&nbsp;the&nbsp;purpose&nbsp;of&nbsp;safeguarding&nbsp;the&nbsp;life,&nbsp;property&nbsp;and&nbsp;other&nbsp;significant&nbsp;legitimate&nbsp;rights&nbsp;and&nbsp;interests&nbsp;of&nbsp;the&nbsp;subject&nbsp;of&nbsp;personal&nbsp;information&nbsp;or&nbsp;other&nbsp;individuals&nbsp;but&nbsp;where&nbsp;it&nbsp;is&nbsp;difficult&nbsp;to&nbsp;obtain&nbsp;his&nbsp;or&nbsp;her&nbsp;consent;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">5)&nbsp;The&nbsp;subject&nbsp;of&nbsp;personal&nbsp;information&nbsp;discloses&nbsp;personal&nbsp;information&nbsp;to&nbsp;the&nbsp;public&nbsp;on&nbsp;his&nbsp;or&nbsp;her&nbsp;own;</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">6)&nbsp;Personal&nbsp;information&nbsp;is&nbsp;collected&nbsp;from&nbsp;information&nbsp;that&nbsp;is&nbsp;lawfully&nbsp;and&nbsp;publicly&nbsp;disclosed,&nbsp;such&nbsp;as&nbsp;legal&nbsp;news&nbsp;reports,&nbsp;government&nbsp;information&nbsp;disclosure&nbsp;and&nbsp;other&nbsp;channels.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">6.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Your&nbsp;Rights</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">During&nbsp;your&nbsp;use&nbsp;of&nbsp;services&nbsp;of&nbsp;Evergreen,&nbsp;we&nbsp;may&nbsp;provide&nbsp;you&nbsp;with&nbsp;corresponding&nbsp;operation&nbsp;settings&nbsp;depending&nbsp;on&nbsp;the&nbsp;specific&nbsp;circumstances&nbsp;of&nbsp;the&nbsp;game&nbsp;products&nbsp;so&nbsp;that&nbsp;you&nbsp;can&nbsp;inquire,&nbsp;delete,&nbsp;correct&nbsp;or&nbsp;withdraw&nbsp;your&nbsp;relevant&nbsp;personal&nbsp;information,&nbsp;and&nbsp;the&nbsp;agreed&nbsp;authorization&nbsp;can&nbsp;be&nbsp;canceled&nbsp;for&nbsp;services&nbsp;that&nbsp;have&nbsp;not&nbsp;been&nbsp;started.&nbsp;You&nbsp;can&nbsp;cancel&nbsp;your&nbsp;personal&nbsp;account&nbsp;by&nbsp;yourself&nbsp;or&nbsp;authorize&nbsp;customer&nbsp;service&nbsp;personnel&nbsp;for&nbsp;assistance.&nbsp;However,&nbsp;once&nbsp;your&nbsp;personal&nbsp;account&nbsp;is&nbsp;successfully&nbsp;canceled,&nbsp;the&nbsp;personal&nbsp;information&nbsp;and&nbsp;game&nbsp;data&nbsp;are&nbsp;destroyed,&nbsp;and&nbsp;you&nbsp;shall&nbsp;not&nbsp;request&nbsp;to&nbsp;restore&nbsp;or&nbsp;recover&nbsp;the&nbsp;such&nbsp;account.&nbsp;You&nbsp;can&nbsp;refer&nbsp;to&nbsp;the&nbsp;specific&nbsp;guidelines&nbsp;of&nbsp;the&nbsp;corresponding&nbsp;game&nbsp;products&nbsp;for&nbsp;operation.&nbsp;In&nbsp;addition,&nbsp;we&nbsp;have&nbsp;also&nbsp;provided&nbsp;a&nbsp;complaint-reporting&nbsp;channel,&nbsp;and&nbsp;your&nbsp;comments&nbsp;will&nbsp;be&nbsp;handled&nbsp;within&nbsp;15&nbsp;days.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">7.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Change</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;may&nbsp;revise&nbsp;the&nbsp;Privacy&nbsp;Policy&nbsp;from&nbsp;time&nbsp;to&nbsp;time.&nbsp;In&nbsp;case&nbsp;of&nbsp;any&nbsp;change&nbsp;to&nbsp;the&nbsp;terms&nbsp;of&nbsp;the&nbsp;Privacy&nbsp;Policy,&nbsp;we&nbsp;will&nbsp;inform&nbsp;you&nbsp;of&nbsp;the&nbsp;changed&nbsp;guidelines&nbsp;by&nbsp;email,&nbsp;call&nbsp;or&nbsp;push&nbsp;notification&nbsp;when&nbsp;the&nbsp;version&nbsp;is&nbsp;updated.&nbsp;Please&nbsp;read&nbsp;the&nbsp;changed&nbsp;privacy&nbsp;protection&nbsp;guidelines&nbsp;or&nbsp;guidelines&nbsp;carefully.&nbsp;Your&nbsp;continuous&nbsp;use&nbsp;of&nbsp;Evergreen&nbsp;means&nbsp;that&nbsp;you&nbsp;agree&nbsp;with&nbsp;us&nbsp;to&nbsp;collect,&nbsp;process&nbsp;or&nbsp;use&nbsp;your&nbsp;personal&nbsp;information&nbsp;in&nbsp;accordance&nbsp;with&nbsp;the&nbsp;updated&nbsp;privacy&nbsp;protection&nbsp;guidelines.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">8.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Protection&nbsp;of&nbsp;Minors</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;attach&nbsp;great&nbsp;importance&nbsp;to&nbsp;the&nbsp;protection&nbsp;of&nbsp;personal&nbsp;information&nbsp;of&nbsp;minors&nbsp;and&nbsp;continue&nbsp;to&nbsp;explore&nbsp;new&nbsp;ways&nbsp;to&nbsp;protect&nbsp;their&nbsp;personal&nbsp;information.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;will&nbsp;actively&nbsp;follow&nbsp;the&nbsp;requirements&nbsp;of&nbsp;the&nbsp;national&nbsp;anti-addiction&nbsp;policy&nbsp;and&nbsp;protect&nbsp;the&nbsp;legal&nbsp;rights&nbsp;of&nbsp;minors&nbsp;by&nbsp;enabling&nbsp;the&nbsp;anti-addiction&nbsp;system.&nbsp;We&nbsp;will&nbsp;determine&nbsp;whether&nbsp;the&nbsp;real&nbsp;name&nbsp;information&nbsp;of&nbsp;the&nbsp;relevant&nbsp;account&nbsp;is&nbsp;a&nbsp;minor&nbsp;by&nbsp;verifying&nbsp;the&nbsp;real&nbsp;name&nbsp;identity&nbsp;and&nbsp;other&nbsp;information&nbsp;so&nbsp;as&nbsp;to&nbsp;decide&nbsp;whether&nbsp;to&nbsp;include&nbsp;such&nbsp;account&nbsp;in&nbsp;the&nbsp;anti-addiction&nbsp;system.&nbsp;What&#8217;s&nbsp;more,&nbsp;we&nbsp;will&nbsp;collect&nbsp;your&nbsp;login&nbsp;time,&nbsp;gaming&nbsp;duration&nbsp;and&nbsp;other&nbsp;information,&nbsp;and&nbsp;guide&nbsp;minors&nbsp;to&nbsp;play&nbsp;reasonably&nbsp;by&nbsp;automatically&nbsp;intervening&nbsp;and&nbsp;limiting&nbsp;minors&#8217;&nbsp;gaming&nbsp;time&nbsp;through&nbsp;the&nbsp;system&nbsp;and&nbsp;enabling&nbsp;the&nbsp;forced&nbsp;disconnection&nbsp;function,&nbsp;etc.&nbsp;In&nbsp;addition,&nbsp;we&nbsp;will&nbsp;also&nbsp;try&nbsp;to&nbsp;contact&nbsp;their&nbsp;guardians&nbsp;for&nbsp;reminding,&nbsp;confirming&nbsp;and&nbsp;dealing&nbsp;with&nbsp;suspected&nbsp;minors&#8217;&nbsp;consumption&nbsp;so&nbsp;as&nbsp;to&nbsp;help&nbsp;minors&nbsp;surf&nbsp;the&nbsp;Internet&nbsp;healthily.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">Meanwhile,&nbsp;in&nbsp;order&nbsp;to&nbsp;better&nbsp;protect&nbsp;the&nbsp;physical&nbsp;and&nbsp;mental&nbsp;health&nbsp;of&nbsp;minors&nbsp;and&nbsp;promote&nbsp;civilized&nbsp;Internet&nbsp;access&nbsp;for&nbsp;minors,&nbsp;we&nbsp;may&nbsp;implement&nbsp;more&nbsp;stringent&nbsp;anti-addiction&nbsp;measures&nbsp;on&nbsp;the&nbsp;basis&nbsp;of&nbsp;the&nbsp;provisions&nbsp;of&nbsp;the&nbsp;national&nbsp;policy&nbsp;on&nbsp;anti-addiction&nbsp;in&nbsp;terms&nbsp;of&nbsp;online&nbsp;games.&nbsp;Meanwhile,&nbsp;we&nbsp;have&nbsp;been&nbsp;constantly&nbsp;researching&nbsp;and&nbsp;testing&nbsp;different&nbsp;kinds&nbsp;of&nbsp;new&nbsp;technologies&nbsp;to&nbsp;protect&nbsp;minors.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">Besides,&nbsp;we&nbsp;may&nbsp;also&nbsp;initially&nbsp;determine&nbsp;whether&nbsp;you&nbsp;are&nbsp;a&nbsp;minor&nbsp;(in&nbsp;terms&nbsp;of&nbsp;voice&nbsp;data,&nbsp;the&nbsp;system&nbsp;will&nbsp;only&nbsp;determine&nbsp;whether&nbsp;you&nbsp;are&nbsp;a&nbsp;child&nbsp;or&nbsp;adult&nbsp;by&nbsp;randomly&nbsp;using&nbsp;part&nbsp;of&nbsp;your&nbsp;online&nbsp;voice)&nbsp;by&nbsp;collecting&nbsp;your&nbsp;game&nbsp;behavior&nbsp;data&nbsp;generated&nbsp;during&nbsp;the&nbsp;game&nbsp;(such&nbsp;as&nbsp;click&nbsp;pressure&nbsp;and&nbsp;radius,&nbsp;relative&nbsp;click&nbsp;position,&nbsp;acceleration&nbsp;direction,&nbsp;gravity&nbsp;direction,&nbsp;etc.,&nbsp;formed&nbsp;when&nbsp;you&nbsp;operate&nbsp;the&nbsp;game&nbsp;on&nbsp;your&nbsp;terminal&nbsp;device,&nbsp;as&nbsp;well&nbsp;as&nbsp;voice&nbsp;data&nbsp;generated&nbsp;when&nbsp;you&nbsp;interact&nbsp;with&nbsp;other&nbsp;players&nbsp;through&nbsp;online&nbsp;voice&nbsp;and&nbsp;other&nbsp;means&nbsp;in&nbsp;the&nbsp;game).&nbsp;If&nbsp;we&nbsp;initially&nbsp;determine&nbsp;that&nbsp;you&nbsp;are&nbsp;a&nbsp;minor,&nbsp;we&nbsp;will&nbsp;attempt&nbsp;to&nbsp;contact&nbsp;your&nbsp;guardians&nbsp;and&nbsp;inform&nbsp;them&nbsp;of&nbsp;the&nbsp;relevant&nbsp;consumption&nbsp;records&nbsp;and&nbsp;other&nbsp;information&nbsp;for&nbsp;the&nbsp;purpose&nbsp;of&nbsp;reminding,&nbsp;confirming&nbsp;and&nbsp;handling.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">If&nbsp;you&nbsp;are&nbsp;the&nbsp;legal&nbsp;guardian&nbsp;of&nbsp;a&nbsp;minor,&nbsp;please&nbsp;check&nbsp;to&nbsp;see&nbsp;if&nbsp;the&nbsp;minor&nbsp;in&nbsp;your&nbsp;custody&nbsp;is&nbsp;using&nbsp;Evergreen&#8217;s&nbsp;services&nbsp;or&nbsp;providing&nbsp;his&nbsp;or&nbsp;her&nbsp;personal&nbsp;information&nbsp;with&nbsp;your&nbsp;authorization&nbsp;and&nbsp;content.&nbsp;If&nbsp;you&nbsp;have&nbsp;any&nbsp;questions&nbsp;about&nbsp;the&nbsp;personal&nbsp;information&nbsp;of&nbsp;the&nbsp;minor&nbsp;in&nbsp;your&nbsp;custody,&nbsp;please&nbsp;contact&nbsp;us&nbsp;by&nbsp;the&nbsp;contact&nbsp;information&nbsp;in&nbsp;Section&nbsp;10.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">9.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Others</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">Privacy&nbsp;Policy&nbsp;is&nbsp;general&nbsp;privacy&nbsp;clauses&nbsp;that&nbsp;are&nbsp;uniformly&nbsp;applicable&nbsp;to&nbsp;&nbsp;Evergreen&nbsp;Group.&nbsp;The&nbsp;covered&nbsp;contents,&nbsp;including&nbsp;but&nbsp;not&nbsp;limited&nbsp;to&nbsp;users&#8217;&nbsp;rights&nbsp;and&nbsp;information&nbsp;security&nbsp;measures,&nbsp;etc.,&nbsp;are&nbsp;applicable&nbsp;to&nbsp;Evergreen&#8217;s&nbsp;users.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><b><span
          style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:'Times New Roman';mso-hansi-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">10.</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Contact&nbsp;Us</span></b><b><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
					<o:p></o:p>
				</span></b></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p>&nbsp;</o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">If&nbsp;you&nbsp;have&nbsp;any&nbsp;other&nbsp;complaints,&nbsp;suggestions,&nbsp;personal&nbsp;information&nbsp;about&nbsp;minors,&nbsp;please&nbsp;be&nbsp;free&nbsp;to&nbsp;contact&nbsp;us.&nbsp;You&nbsp;can&nbsp;also&nbsp;send&nbsp;your&nbsp;questions&nbsp;to.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
      <p class=MsoNormal><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">We&nbsp;will&nbsp;review&nbsp;the&nbsp;issues&nbsp;involved&nbsp;as&nbsp;soon&nbsp;as&nbsp;possible&nbsp;and&nbsp;reply&nbsp;within&nbsp;15&nbsp;days&nbsp;after&nbsp;verifying&nbsp;your&nbsp;user&nbsp;identity.</span><span
          style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
				<o:p></o:p>
			</span></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.privacy{
  padding:0 1rem;
  word-break: break-word;
}
@font-face {
  font-family: "Times New Roman";
}

@font-face {
  font-family: "宋体";
}

@font-face {
  font-family: "Calibri";
}

@font-face {
  font-family: "DejaVu Sans";
}

p.MsoNormal {
  mso-style-name: 正文;
  mso-style-parent: "";
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

h1 {
  mso-style-name: "标题 1";
  mso-style-next: 正文;
  margin-top: 17.4000pt;
  margin-bottom: 10.5000pt;
  page-break-after: avoid;
  mso-pagination: lines-together;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 17.0000pt;
  mso-font-kerning: 1.0000pt;
}

h2 {
  mso-style-name: "标题 2";
  mso-style-next: 正文;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 18.0000pt;
}

h3 {
  mso-style-name: "标题 3";
  mso-style-next: 正文;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 13.5000pt;
}

h4 {
  mso-style-name: "标题 4";
  mso-style-next: 正文;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 12.0000pt;
}

span.10 {
  font-family: 'Times New Roman';
}

span.15 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.16 {
  font-family: 'Times New Roman';
  font-size: 10.5000pt;
}

span.17 {
  font-family: 'Times New Roman';
  font-weight: bold;
}

span.18 {
  font-family: 'Times New Roman';
  color: rgb(5, 99, 193);
  text-decoration: underline;
  text-underline: single;
}

span.19 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.20 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoCommentText {
  mso-style-name: 批注文字;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

p.p {
  mso-style-name: "普通\(网站\)";
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
}

p.MsoAcetate {
  mso-style-name: 批注框文本;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoFooter {
  mso-style-name: 页脚;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoHeader {
  mso-style-name: 页眉;
  margin: 0pt;
  margin-bottom: .0001pt;
  border-top: none;
  mso-border-top-alt: none;
  border-right: none;
  mso-border-right-alt: none;
  border-bottom: none;
  mso-border-bottom-alt: none;
  border-left: none;
  mso-border-left-alt: none;
  padding: 1pt 4pt 1pt 4pt;
  mso-pagination: none;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoCommentSubject {
  mso-style-name: 批注主题;
  mso-style-parent: 批注文字;
  mso-style-next: 批注文字;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.msoIns {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: underline;
  text-underline: single;
  color: blue;
}

span.msoDel {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: line-through;
  color: red;
}

table.MsoNormalTable {
  mso-style-name: 普通表格;
  mso-style-parent: "";
  mso-style-noshow: yes;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin: 0pt;
  mso-para-margin-bottom: .0001pt;
  mso-pagination: widow-orphan;
  font-family: 'Times New Roman';
  font-size: 10.0000pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}

table.MsoTableGrid {
  mso-style-name: 网格型;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-border-top-alt: 0.7500pt solid rgb(0, 0, 0);
  mso-border-left-alt: 0.7500pt solid rgb(0, 0, 0);
  mso-border-bottom-alt: 0.7500pt solid rgb(0, 0, 0);
  mso-border-right-alt: 0.7500pt solid rgb(0, 0, 0);
  mso-border-insideh: 0.7500pt solid rgb(0, 0, 0);
  mso-border-insidev: 0.7500pt solid rgb(0, 0, 0);
  mso-para-margin: 0pt;
  mso-para-margin-bottom: .0001pt;
  mso-pagination: widow-orphan;
  font-family: 'Times New Roman';
  font-size: 10.0000pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}

@page {
  mso-page-border-surround-header: no;
  mso-page-border-surround-footer: no;
}

@page Section0 {
  margin-top: 72.0000pt;
  margin-bottom: 72.0000pt;
  margin-left: 90.0000pt;
  margin-right: 90.0000pt;
  size: 595.2500pt 841.8500pt;
  layout-grid: 15.6000pt;
}

div.Section0 {
  page: Section0;
}
</style>
